import { Typography, Box } from '@mui/material';

// ----------------------------------------------------------------------

const TitleStyle = {
    fontWeight: 'bold',
    // color: '#464646'
};
const SubtitleStyle = {
    fontWeight: 'bold'
};
const CaptionStyle = {
    color: '#637381'
}

// ----------------------------------------------------------------------

type Props = {
    title: string;
    subtitle: string;
    caption: string;
  };
  
// ----------------------------------------------------------------------

export default function PageHeader({ title, subtitle, caption }: Props) {
  return (
    <Box>
        <Typography sx={TitleStyle}>{title}</Typography> 
        <Typography sx={SubtitleStyle} variant="h5">{subtitle}</Typography>
        <Typography sx={CaptionStyle}>{caption}</Typography>
    </Box>
  );
}
