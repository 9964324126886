const parseBizhavenError = (error: any) => {
    let message = 'Unknown error occurred';

    if (error.data) {
      let errors = '';

      for (const x in error.data) {
        errors += `${error.data[x]}`;
      }

      message = errors;
    }

    return message;
};

const BizhavenErrorMultiLine = (error: any) => {
    let message = 'Unknown error occurred';

    if (error.data) {
        let errors = [];

        for (const x in error.data) {
            errors.push(`${error.data[x]}`);
        }

        return errors
    }

    return [];
};

export { parseBizhavenError, BizhavenErrorMultiLine };
