import { ReactNode } from 'react';
import { Container, Box, Alert, AlertTitle } from '@mui/material';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: string[];
  children: ReactNode | string;
};

const useCurrentRole = (user: any) => {
  // Logic here to get current user role
  // const role = 'admin';
  // return role;

  if(!user){
    return '';
  }

  return user.role_id + ''; // convert to string
};

export default function RoleBasedGuard({ accessibleRoles, children }: RoleBasedGuardProp) {
  const { user, isAuthenticated, isInitialized } = useAuth();

  const currentRole = useCurrentRole(user);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    return <Login />;
  }

  if (!accessibleRoles.includes(currentRole)) {
    return (
      <Box
          sx={{
            px: '2',
            pt: '24px'
          }}>
          <Container>
            <Alert severity="error">
              <AlertTitle>Permission Denied</AlertTitle>
              You do not have permission to access this page
            </Alert>
          </Container>
        </Box>
    );
  }

  return <>{children}</>;
}
