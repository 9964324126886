import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import { useState, useCallback, useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { 
  Box, Stack, Button, Alert, DialogTitle, 
  Typography, DialogActions, 
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField, RHFUploadSingleFile } from '../../../components/hook-form';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// util
import { parseBizhavenError } from '../../../utils/parseError';

// ----------------------------------------------------------------------

const getInitialValues = (doc: DocumentType | null) => {
  if(doc != null){
    const _doc = {
      name: doc.name,
      file: null,
      afterSubmit: ''
    };

    return _doc;
  }else{
    const _doc = {
      name: '',
      file: null,
      afterSubmit: ''
    };

    return _doc;
  }
};

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

type DocumentCategoryType = {
  id: string;
  name: string;
  type: string;
  unique_id: string;
}

const initialCategories: DocumentCategoryType[] = [];

const initialFormOptions = {
  categories: initialCategories
}

type DocumentType = {
  id: string;
  name: string;
  file_name: string;
  company_id: string;
  url: string;
  size: string;
  status: string;
  type: string;
  type_id: string;
  created_by: string;
  created_at: string;
  updated_at: string;
}

type FormValuesProps = {
  name: string;
  file: File | any;
};

type Props = {
  type: string;
  typeId: string;
  doc: DocumentType | null;
  onCancel: Function;
};

// ----------------------------------------------------------------------

export default function DocumentForm({ doc, onCancel }: Props) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [curCategory, setCurCategory] = useState('');
  const [formOptions, setFormOptions] = useState(initialFormOptions);

  const getFormOptions = useCallback(async () => {
    try {
      let url = `/app/admin/document/form-options`;

      const response = await axios.get(url);

      if (isMountedRef.current) {
        let { data } = response.data;

        setFormOptions(data);
        if(doc){
          
          let uniqueId = doc.type + '_' + doc.type_id;

          setCurCategory(uniqueId);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [isMountedRef, doc]);

  useEffect(() => {
    getFormOptions();
  }, [getFormOptions]);

  const EventSchema = Yup.object().shape({
    name: Yup.string().max(191).required('Name is required'),
    file: Yup.mixed(),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(doc),
  });

  const {
    reset,
    setValue,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'file',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const onSubmit = async (data: FormValuesProps) => {
    try {
      var formData = new FormData();

      let cat = getSelectedCategory(curCategory);

      if(cat != null){
        formData.append("type", cat.type);
        formData.append("type_id", cat.id);
      }

      formData.append("name", data.name);

      let url = '/app/admin/document';
      
      if(doc?.id){
        url = url + '/' + doc.id
        formData.append("_method", 'PUT');
      }else{
        formData.append("file", data.file);
      }

      const response = await axios.post(url, formData);

      if (isMountedRef.current) {
        let msg = 'Document updated!';

        let doc = response.data.data;

        enqueueSnackbar(msg);
        onCancel(doc);
        reset();
      }
    } catch (error) {
      console.log(error);

      if (isMountedRef.current) {
        let message = parseBizhavenError(error);
        error.message = message;
        
        setError('afterSubmit', error);
      }
    }
  };

  const getSelectedCategory = (uniqueId: string): DocumentCategoryType | null => {
    let cat = formOptions.categories.find((item) => item.unique_id.toString() === uniqueId.toString());

    if(!cat){
      return null;
    }else{
      return cat;
    }
  }

  const handleCategorySelect = (event: any) => {
    const { target: { value } } = event;
    setCurCategory(value);
  };

  return (
    <>
      <DialogTitle sx={{ mb: 1 }}>Document</DialogTitle>

      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} sx={{ p: 3 }}>
          <RHFTextField name="name" label="Name"/>

          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              value={curCategory}
              label="Category"
              onChange={handleCategorySelect}
            >
              {formOptions.categories.map((option: any) => (
                <MenuItem key={option.unique_id} value={option.unique_id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {!(!!doc && doc.id) && (
            <div>
              <LabelStyle>File (pdf)</LabelStyle>
              <RHFUploadSingleFile
                name="file"
                accept=".pdf"
                maxSize={11534336}
                onDrop={handleDrop}
              />
            </div>
          )}

          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
        </Stack>

        <DialogActions>
          <Box sx={{ flexGrow: 1 }} />

          <Button variant="outlined" color="inherit" onClick={() => onCancel()}>
            Cancel
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            loadingIndicator="Loading..."
          >
            { (doc?.id) ? 'Update' : 'Add' }
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </>
  );
}
