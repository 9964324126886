import { useState, useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'src/utils/axios';
import moment from 'moment';
// @mui
import {
    Grid, Typography, Box,
    Button, Link, DialogTitle,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
// utils
import { fDateShort } from '../../../../utils/formatTime';
// hooks
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
// components
import { DialogAnimate } from '../../../../components/animate';
import { SkeletonGraph } from '../../../../components/skeleton';
// @types
import { SurveyType } from 'src/@types/user';

// ----------------------------------------------------------------------

const initSurveys: SurveyType[] = [];

type ModalState = {
    isOpenModal: boolean;
    surveys: any;
};
const initialModalState: ModalState = {
    isOpenModal: false,
    surveys: initSurveys,
};

// ----------------------------------------------------------------------

export default function UpcomingSurveysSection() {
    const isMountedRef = useIsMountedRef();
    const [modalState, setModalState] = useState(initialModalState);
    const [isLoading, setIsLoading] = useState(false);
    const [surveys30Day, setSurveys30Day] = useState(initSurveys);
    const [surveys90Day, setSurveys90Day] = useState(initSurveys);

    const getUpcomingSurveysData = useCallback(async () => {
        try {
            let url = `/app/shared/surveys`;
            let data = {
                due_before: moment().add(90, 'day').format('YYYY-MM-DD')
            }

            const response = await axios.get(url, {
                params: data,
            });

            if (isMountedRef.current) {
                let { data } = response.data;

                await setAssignedData(data);
            }
        } catch (err) {
            console.log(err);
        }
    }, [isMountedRef]);

    const setAssignedData = async (data: any) => new Promise((resolve, reject) => {
        let tempSurveys30Day: SurveyType[] = [];
        let tempSurveys90Day: SurveyType[] = [];

        const compareDate30 = moment().add(30, 'day').format('YYYY-MM-DD');

        for (let i = 0; i < data.length; i++) {
            let curUser: SurveyType = data[i];

            if(!!curUser.completed_at){
                continue;
            }else if (curUser.due_at > compareDate30) {
                tempSurveys90Day.push(curUser);
            } else {
                tempSurveys30Day.push(curUser);
            }
        }

        setSurveys30Day(tempSurveys30Day);
        setSurveys90Day(tempSurveys90Day);
        resolve(null);
    });

    const init = useCallback(async () => {
        setIsLoading(true);

        await getUpcomingSurveysData();

        setIsLoading(false);
    }, [getUpcomingSurveysData]);

    useEffect(() => {
        init();
    }, [init]);

    /*********
     * MODAL *
     *********/

    const openModal = (type: string) => {
        if (type === '30_day') {
            setModalState({
                isOpenModal: true,
                surveys: surveys30Day,
            });
        } else if (type === '90_day') {
            setModalState({
                isOpenModal: true,
                surveys: surveys90Day,
            });
        }
    }

    const closeModal = () => {
        setModalState({
            isOpenModal: false,
            surveys: initSurveys,
        });
    }

    return (
        <>
            {isLoading ? (
                <SkeletonGraph />
            ) : (
                <>
                    <Grid container spacing={3} sx={{ p: 3, pt: 0 }}>
                        <Grid item xs={6}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography variant="body2" sx={{ ...SUBHEADER_STYLE }}>Due In Next 30 Days</Typography>
                                <Box>
                                    <Button
                                        onClick={() => (openModal('30_day'))}
                                        variant="text"
                                        size="large"
                                        sx={{ ...BUTTON_STYLE }}
                                    >
                                        {surveys30Day.length}
                                    </Button>
                                </Box>
                                <Box>
                                    <Link
                                        to={'/admin/performance/surveys'}
                                        component={RouterLink}
                                    >
                                        View All
                                    </Link>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography variant="body2" sx={{ ...SUBHEADER_STYLE }}>Due in Next 90 Days</Typography>
                                <Box>
                                    <Button
                                        onClick={() => (openModal('90_day'))}
                                        variant="text"
                                        size="large"
                                        sx={{ ...BUTTON_STYLE }}
                                    >
                                        {surveys90Day.length}
                                    </Button>
                                </Box>
                                <Box>
                                    <Link
                                        to={'/admin/performance/surveys'}
                                        component={RouterLink}
                                    >
                                        View All
                                    </Link>
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>
                </>
            )}

            <DialogAnimate open={modalState.isOpenModal} onClose={closeModal}>
                <DialogTitle sx={{ mb: 1 }}>Upcoming Surveys</DialogTitle>

                <TableContainer component={Paper} sx={{ p: 2 }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Title</TableCell>
                                <TableCell>Due At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {modalState.surveys.map((row: SurveyType) => (
                                <TableRow
                                    key={row.id}
                                >
                                    <TableCell>{row.title}</TableCell>
                                    <TableCell>{fDateShort(row.due_at)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogAnimate>
        </>
    );
}

const SUBHEADER_STYLE = {
    margin: '0',
    lineHeight: '1.5714285714285714',
    fontSize: '0.875rem',
    fontWeight: '400',
    color: '#637381',
    display: 'block',
    marginTop: '4px'
};

const BUTTON_STYLE = {
    width: '90px',
    fontSize: '2rem',
    marginTop: '4px',
    marginBottom: '4px'
};
