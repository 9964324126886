import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Typography, Grid, Alert, Button } from '@mui/material';
// components
import { RHFSelect, RHFTextField } from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useAuth from 'src/hooks/useAuth';
// util
import { parseBizhavenError } from '../../../utils/parseError';
import { ROLE } from 'src/config';

// ----------------------------------------------------------------------

const NEW_COURSE_VALUE = 'create_new_course';

const defaultValues = {
    user_ids: [''],
    afterSubmit: ''
};

type NewAssignmentType = {
    user_id: string;
    training_id: string;
    due_at: string;
}

const initNewAssignment: NewAssignmentType = { user_id: '', training_id: '', due_at: '' };

type Props = {
    formOptions: any;
    updateUser: Function;
    onNewCourse: Function;
};

// ----------------------------------------------------------------------

export default function TrackCourseAssignForm({ formOptions, updateUser, onNewCourse }: Props) {
    const { user } = useAuth();
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const [newAssignment, setNewAssignment] = useState(initNewAssignment);

    const EventSchema = Yup.object().shape({
        //
    });

    const methods = useForm({
        resolver: yupResolver(EventSchema),
        defaultValues
    });

    const {
        formState: { errors },
    } = methods;

    const onSubmit = async (newValues: NewAssignmentType) => {
        try {
            let url = `/app/shared/training/${newValues.training_id}/assign-user`;

            let formData = new FormData();
            formData.append('user_id', newValues.user_id);
            formData.append('due_at', newValues.due_at);

            const response = await axios.post(url, formData);

            if (isMountedRef.current) {
                let msg = 'Assignment updated!';

                let { data } = response.data;

                enqueueSnackbar(msg);
                updateUser(data);
                setNewAssignment(initNewAssignment);
            }
        } catch (error) {
            console.log(error);

            if (isMountedRef.current) {
                let msg = parseBizhavenError(error);

                enqueueSnackbar(msg, {
                    variant: 'error'
                });
            }
        }
    };

    const handleUserSelect = (event: any) => {
        const { target: { value } } = event;
        let newValues = { ...newAssignment, user_id: value };

        setNewAssignment(newValues);
    };

    const handleTrainingSelect = (event: any) => {
        const { target: { value } } = event;

        if (value === NEW_COURSE_VALUE) {
            onNewCourse();
        } else {
            let newValues = { ...newAssignment, training_id: value };
            setNewAssignment(newValues);
        }
    };

    const handleDueAtSelect = (event: any) => {
        const { target: { value } } = event;

        setNewAssignment({ ...newAssignment, due_at: value });
    };

    const isValidDate = (dateString: string) => {
        let date = new Date(dateString);
        if (isNaN(date.getTime())) {
          return false;
        }

        let parts = dateString.split("-");

        if (parts.length !== 3) {
          return false;
        }

        let [year, month, day] = parts.map(part => parseInt(part, 10));

        if (month < 1 || month > 12 || day < 1 || day > 31 || year < 1000 || year > 9999) {
          return false;
        }

        return true;
    }

    const checkSubmission = () => {
        if (!!newAssignment.user_id && !!newAssignment.training_id && !!newAssignment.due_at && isValidDate(newAssignment.due_at)) {
            onSubmit(newAssignment);
        }
    }

    return (
        <Box>
            <Typography variant="subtitle1" mb={1}>
                <Iconify icon={'eva:plus-fill'} sx={{ marginBottom: '-3px' }} /> Track a Training
            </Typography>

            <Grid container spacing={2}>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <RHFSelect
                        name="employee"
                        label="Employee"
                        placeholder=""
                        value={newAssignment.user_id}
                        onChange={handleUserSelect}
                    >
                        <option value="" />
                        {formOptions.users.map((option: any) => (
                            <option key={option.id} value={option.id}>
                                {option.first_name + ' ' + option.last_name}
                            </option>
                        ))}
                    </RHFSelect>
                </Grid>
                <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <RHFSelect
                        name="training_id"
                        label="Training"
                        placeholder=""
                        value={newAssignment.training_id}
                        onChange={handleTrainingSelect}
                    >
                        <option value="" />
                        {user && user.role_id.toString() === ROLE.ADMIN && (
                            <optgroup label="New">
                                <option value={NEW_COURSE_VALUE}>
                                    + Add a Training
                                </option>
                            </optgroup>
                        )}
                        <optgroup label="Training Courses">
                            {formOptions.trainings.map((option: any) => (
                                <option key={option.id} value={option.id}>
                                    {option.title}
                                </option>
                            ))}
                        </optgroup>
                    </RHFSelect>
                </Grid>
                <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                    <RHFTextField
                        type="date"
                        name="due_at"
                        label="Deadline"
                        value={newAssignment.due_at}
                        onChange={handleDueAtSelect}
                        InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xl={1} lg={1} md={1} sm={12} xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 1 }}
                        onClick={() => checkSubmission()}
                    >
                        Assign
                    </Button>
                </Grid>
            </Grid>

            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
        </Box>
    );
}
