// components
// import SvgIconStyle from '../../../../components/SvgIconStyle';
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
// );

// const ICONS = {
//   user: getIcon('ic_user'),
//   ecommerce: getIcon('ic_ecommerce'),
//   analytics: getIcon('ic_analytics'),
//   dashboard: getIcon('ic_dashboard'),
// };

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      { title: 'Dashboard', path: '/super/dashboard', icon: <Iconify icon={'bx:bxs-dashboard'} width={32} height={32} /> },
    ],
  }
];

export default navConfig;
