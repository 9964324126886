import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import { useState, useCallback, useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
    Stack, Alert
} from '@mui/material';
// components
import { FormProvider, RHFSelect } from '../../../components/hook-form';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// util
import { parseBizhavenError } from '../../../utils/parseError';

// ----------------------------------------------------------------------

const defaultValues = {
    type: '',
    afterSubmit: ''
};

type ReminderOptionType = {
    key: string;
    label: string;
};
const initReminderOptions: ReminderOptionType[] = [];

type Props = {
    courseId: string;
    reminderValue: string;
};

// ----------------------------------------------------------------------

export default function ReminderTimeForm({ courseId, reminderValue }: Props) {
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const [reminderOptions, setReminderOptions] = useState(initReminderOptions);

    const EventSchema = Yup.object().shape({
        type: Yup.mixed().required('Reminder time is required')
    });

    const methods = useForm({
        resolver: yupResolver(EventSchema),
        defaultValues
    });

    const {
        setValue,
        setError,
        formState: { errors },
    } = methods;

    const getFormOptions = useCallback(async () => {
        try {
            let url = '/app/admin/training/reminder/form-options';

            const response = await axios.get(url, {
                params: {},
            });

            if (isMountedRef.current) {
                let { data } = response.data;

                setReminderOptions(data.reminder_options);
                setValue('type', reminderValue);
            }
        } catch (err) {
            console.log(err);
        }
    }, [isMountedRef, reminderValue, setValue]);

    useEffect(() => {
        getFormOptions();
    }, [getFormOptions]);

    // const onSubmit = async (data: FormValuesProps) => {
    const onSubmit = async (reminderType: string) => {
        try {
            let formData = new FormData();

            if(reminderType === ''){
                formData.append("type", 'no-reminder');
            }else{
                formData.append("type", reminderType);
            }

            let url = `/app/admin/training/${courseId}/reminder`;
            await axios.post(url, formData);

            if (isMountedRef.current) {
                let msg = '';

                if (reminderValue != null) {
                    msg = 'Reminder updated!';
                } else {
                    msg = 'Reminder set!';
                }

                enqueueSnackbar(msg);
                // reset();
            }
        } catch (error) {
            console.log(error);

            if (isMountedRef.current) {
                let message = parseBizhavenError(error);
                error.message = message;

                setError('afterSubmit', error);
            }
        }
    };

    const onTypeChange = (event: any) => {
        const { target: { value } } = event;

        setValue('type', value);
        onSubmit(value);
    };

    return (
        <FormProvider methods={methods}>
            <Stack spacing={3} sx={{ pb: 3, pt: 3 }}>
                <RHFSelect 
                    InputLabelProps={{ shrink: true }} 
                    sx={{ width: '300px' }}
                    name="type"
                    label="Remind Me"
                    placeholder=""
                    onChange={onTypeChange}
                >
                    <option key="" value="">No Reminder</option>

                    {reminderOptions.map((option: any) => (
                        <option key={option.key} value={option.key}>
                            {option.label}
                        </option>
                    ))}
                </RHFSelect>

                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
            </Stack>
        </FormProvider>
    );
}