import FullCalendar, { EventClickArg } from '@fullcalendar/react'; // => request placed at the top
import dayGridPlugin from '@fullcalendar/daygrid';
import { useState, useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'src/utils/axios';
// @mui
import {
  Card, Button, Container,
  DialogTitle, Divider, Box
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
// routes
import useSettings from '../../../hooks/useSettings';
import useResponsive from '../../../hooks/useResponsive';
// components
import Iconify from '../../../components/Iconify';
import { DialogAnimate } from '../../../components/animate';
// sections
import { CalendarStyle, CalendarToolbar } from './calendar-components';
import { CompanyEventForm } from '../company-event';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// utils
import moment from 'moment';
// @types
import { CompanyEventType } from 'src/@types/user';

// ----------------------------------------------------------------------

enum EventTypeEnum {
  TimeOff,
  CompanyEvent
};

type CalendarEventType = {
  allDay: boolean;
  short_title: string;
  title: string;
  description: string;
  start: string;
  end: string;
  status: number;
  status_name: string;
  textColor: string;
  eventType: EventTypeEnum;
  resource_id: string;
}

const defaultEvents: CalendarEventType[] = [];
const defaultCompanyEvents: CompanyEventType[] = [];

type ModalState = {
  event: CompanyEventType | null,
  isOpenModal: boolean;
};
const initialModalState: ModalState = {
  event: null,
  isOpenModal: false
};

const STATUS_DELETED = 0;
const STATUS_PENDING = 1;
const STATUS_APPROVED = 2;
const STATUS_REJECTED = 3;
const STATUS_COMPANY_EVENT = 4;

type Props = {
  showViewButton: boolean;
};

export default function AdminTimeOffCalendar({ showViewButton }: Props) {
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'sm');
  const calendarRef = useRef<FullCalendar>(null);
  const view = 'dayGridMonth';
  const { themeStretch } = useSettings();
  const [events, setEvents] = useState(defaultEvents);
  const [companyEvents, setCompanyEvents] = useState(defaultCompanyEvents);
  const [modalState, setModalState] = useState(initialModalState);
  const [date, setDate] = useState(new Date());

  const getEventColor = useCallback((status: number): string => {
    let color = '';

    switch (status) {
      case STATUS_COMPANY_EVENT:
        color = theme.palette.info.main;
        break;
      case STATUS_PENDING:
        color = theme.palette.warning.main;
        break;
      case STATUS_APPROVED:
        color = theme.palette.success.main;
        break;
      case STATUS_DELETED:
      case STATUS_REJECTED:
        color = theme.palette.error.main;
        break;
      default:
        color = theme.palette.primary.main;
        break;
    }

    return color;
  }, [theme]);

  const getCompanyEventById = (id: string): CompanyEventType | null => {
    let foundEvent = null;

    companyEvents.forEach((item: any) => {
      if (item.id.toString() === id.toString()) {
        foundEvent = item;
      }
    })

    return foundEvent;
  }

  const getTimeOffRequests = useCallback(async (startDate: string, endDate: string) => {
    try {
      const response = await axios.get('/app/shared/leave-requests', {
        params: {
          start_date: startDate,
          end_date: endDate
        },
      });
      if (isMountedRef.current) {
        let formattedEvents = response.data.data.map((item: any) => {
          let color = getEventColor(item.status);

          return {
            allDay: true,
            short_title: item.title,
            title: item.user_name,
            description: item.description,
            start: item.start_date,
            end: item.end_date,
            status: item.status,
            status_name: item.status_name,
            textColor: color,
            eventType: EventTypeEnum.TimeOff,
            resource_id: item.id
          };
        });

        return formattedEvents;
      }
    } catch (err) {
      //
    }
  }, [isMountedRef, getEventColor]);

  const getCompanyEvents = useCallback(async (startDate: string, endDate: string) => {
    try {
      const response = await axios.get('/app/admin/company-event', {
        params: {
          start_date: startDate,
          end_date: endDate
        },
      });
      if (isMountedRef.current) {
        setCompanyEvents(response.data.data);

        let formattedEvents = response.data.data.map((item: any) => {
          let color = getEventColor(STATUS_COMPANY_EVENT);

          return {
            allDay: true,
            short_title: item.title,
            title: item.title,
            description: item.type,
            start: item.event_date,
            end: item.event_date,
            status: STATUS_COMPANY_EVENT,
            status_name: 'company_event',
            textColor: color,
            eventType: EventTypeEnum.CompanyEvent,
            resource_id: item.id
          };
        });

        return formattedEvents;
      }
    } catch (err) {
      //
    }
  }, [isMountedRef, getEventColor]);

  const getEvents = useCallback(async (startDate: string, endDate: string) => {
    let timeOffEvents = await getTimeOffRequests(startDate, endDate);
    let companyEvents = await getCompanyEvents(startDate, endDate);

    if (isMountedRef.current) {
      setEvents([...timeOffEvents, ...companyEvents]); // test
    }
  }, [getCompanyEvents, getTimeOffRequests, isMountedRef]);

  useEffect(() => {
    let startDate = moment().format('YYYY-MM-01');
    let endDate = moment().add(1, 'month').format('YYYY-MM-01');

    getEvents(startDate, endDate);
  }, [getEvents]);

  const handleClickToday = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();

      // update calendar
      let date = calendarApi.getDate();

      setDate(calendarApi.getDate());

      // update events
      let startDate = moment(date).format('YYYY-MM-01');
      let endDate = moment(date).add(1, 'month').format('YYYY-MM-01');

      getEvents(startDate, endDate);
    }
  };

  const handleClickDatePrev = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.prev();

      let date = calendarApi.getDate();

      // update calendar
      setDate(calendarApi.getDate());

      // update events
      let startDate = moment(date).format('YYYY-MM-01');
      let endDate = moment(date).add(1, 'month').format('YYYY-MM-01');

      getEvents(startDate, endDate);
    }
  };

  const handleClickDateNext = () => {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();

      let date = calendarApi.getDate();

      // update calendar
      setDate(date);

      // update events
      let startDate = moment(date).format('YYYY-MM-01');
      let endDate = moment(date).add(1, 'month').format('YYYY-MM-01');

      getEvents(startDate, endDate);
    }
  };

  const handleSelectEvent = (arg: EventClickArg) => {
    let type: EventTypeEnum = arg.event._def.extendedProps.eventType;

    if(type === EventTypeEnum.TimeOff){
      navigate('/admin/time-off?type=calendar', { replace: true });
      return;
    }else{
      let id = arg.event._def.extendedProps.resource_id
      openModal(id);
    }
  };

  const updateCompanyEvents = (event: CompanyEventType) => {
    let found = companyEvents.find((el) => (el.id === event.id));

    if(!!found){
      setCompanyEvents(
        companyEvents.map(el => ((el.id === event.id) ? event : el))
      );
    }else{
      setCompanyEvents([...companyEvents, event]);
    }
  }

  const updateCalendarEvents = (event: CalendarEventType) => {
    let found = events.find((el) => (el.resource_id === event.resource_id && el.eventType === EventTypeEnum.CompanyEvent));
  
    if(!!found){
        setEvents(
          events.map(el => ((el.resource_id === event.resource_id && el.eventType === EventTypeEnum.CompanyEvent) ? event : el))
        );
    }else{
        setEvents([...events, event]);
    }
  }

  const onDeleteCompanyEvent = (event: CompanyEventType) => {
    setCompanyEvents(
      companyEvents.filter(item => item.id !== event.id)
    );

    setEvents(
      events.filter(el => !(el.resource_id === event.id && el.eventType === EventTypeEnum.CompanyEvent))
    );

    closeModal();
  }

  const handleCloseModal = (event: CompanyEventType | null) => {
    if(event == null){
      closeModal();
    }else if(event != null){
      let color = getEventColor(STATUS_COMPANY_EVENT);
      let newEvent: CalendarEventType = {
          allDay: true,
          short_title: event.title,
          title: event.title,
          description: event.type,
          start: event.event_date,
          end: event.event_date,
          status: STATUS_COMPANY_EVENT,
          status_name: 'company_event',
          textColor: color,
          eventType: EventTypeEnum.CompanyEvent,
          resource_id: event.id
      };

      updateCompanyEvents(event);
      updateCalendarEvents(newEvent);
      closeModal();
    }
  };

  // OPEN MODAL
  const openModal = (id: string | null) => {
    if(id){
      let _event: CompanyEventType | null = getCompanyEventById(id);

      if(_event != null){
        setModalState({
          event: _event,
          isOpenModal: true
        });

        return;
      }
    }

    // else open modal without event
    setModalState({
      event: null,
      isOpenModal: true
    });
  }

  // CLOSE MODAL
  const closeModal = () => {
    setModalState({
      ...modalState,
      isOpenModal: false
    });
  }

  return (
    <Container sx={{ padding: '0px !important' }} maxWidth={themeStretch ? false : 'xl'}>
      <Card>
        <CalendarStyle>
          <CalendarToolbar
            date={date}
            view={view}
            onNextDate={handleClickDateNext}
            onPrevDate={handleClickDatePrev}
            onToday={handleClickToday}
          />
          <FullCalendar
            events={events}
            ref={calendarRef}
            rerenderDelay={10}
            initialDate={date}
            initialView={view}
            dayMaxEventRows={3}
            eventDisplay="block"
            headerToolbar={false}
            allDayMaintainDuration
            eventResizableFromStart
            eventClick={handleSelectEvent}
            // height={isDesktop ? 720 : 'auto'}
            height={isDesktop ? 436 : 'auto'} // changed the height to make the day boxes square
            plugins={[
              dayGridPlugin,
            ]}
          />
        </CalendarStyle>

        {showViewButton && (
          <Divider />
        )}
        {showViewButton && (
          <Box sx={{ p: 3, textAlign: 'center' }}>
            {/* <Button
              size="small"
              color="primary"
              variant="contained"
              to={'/admin/time-off'}
              component={RouterLink}
            >
              View Calendar
            </Button> */}

            {/* <Box sx={{ flexGrow: 1 }} /> */}

            <Button size="small" variant="contained" color="primary" onClick={() => openModal(null)} startIcon={<Iconify icon={'eva:plus-fill'} />} >
              Add Event
            </Button>
          </Box>
        )}
      </Card>

      <DialogAnimate open={modalState.isOpenModal} onClose={closeModal}>
        <DialogTitle sx={{ mb: 1 }}>Company Event</DialogTitle>
        <CompanyEventForm onCancel={handleCloseModal} onDelete={onDeleteCompanyEvent} curEvent={modalState.event} />
      </DialogAnimate>
    </Container>
  );
}
