import React, {useState, useCallback, useMemo, useEffect} from 'react';
import { useSnackbar } from 'notistack';
import debounce from 'lodash/debounce';
import axios from 'src/utils/axios';
import moment from 'moment';
// form
import { useForm } from 'react-hook-form';
// @mui
import {
    Box, Stack, Typography, Button, IconButton, Grid,
    TableContainer, Table, TableBody, TableRow, TableCell, TablePagination,
    DialogTitle, DialogActions, Tooltip
} from '@mui/material';
// components
import { FormProvider, RHFTextField, RHFSelect } from '../../../components/hook-form';
import { DialogAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// util
import { parseBizhavenError } from '../../../utils/parseError';
import { dateIsPassed, fDateShorter } from '../../../utils/formatTime';
// sections
import { TrackCourseHead, TrackCourseToolbar, TrackCourseAssignForm } from '.';
// config
import { TRAINING_TYPE } from '../../../config';
import { ConfirmationDialog } from "../../../components/dialogs";

import { DatePicker } from "antd";
import dayjs, {Dayjs} from "dayjs";

// Styles TrackCourseTable
import './TrackCourseTable.css';



// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'training_title', label: 'Training', alignRight: false },
    { id: 'training_type_label', label: 'Type', alignRight: false },
    { id: 'due_at', label: 'Due', alignRight: false },
    { id: 'completed_at', label: 'Complete', alignRight: false },
    { id: 'expires_at', label: 'Expires', alignRight: false },
    { id: 'certificate_url', label: 'Cert', alignRight: false },
    { id: 'user_training_expire_notification_type', label: 'Reminder', alignRight: false }
];

// ----------------------------------------------------------------------

type AssignedUserType = {
    id: string;
    user_id: number;
    email: string;
    first_name: string;
    last_name: string;
    image: string;
    profile_image_url: string;
    total_modules_count: number;
    completed_modules_count: number;
    training_id: string;
    training_type_key: string;
    training_type_label: string;
    training_title: string;
    training_access_user_id: string;
    due_at: string;
    completed_at: string;
    expires_at: string;
    certificate_url: string;
    user_training_expire_notification_type: string;
}

type NewAssignmentType = {
    user_id: string;
    training_id: string;
    due_at: string;
}

type FilterType = {
    query: string;
    training_id: string;
    due_at: string;
}

const initFilter: FilterType = {
    query: '',
    training_id: '',
    due_at: ''
}

type Props = {
    users: AssignedUserType[];
    formOptions: any;
    onUserClick: Function;
    onTrackCourseCertificateClick: Function;
    onUpdateUser: Function;
    onUserCompleted: Function;
    onNewCourse: Function;
};

type ConfirmModalState = {
    isOpenModal: boolean;
    user: AssignedUserType | null;
};
const initialConfirmModalState: ConfirmModalState = {
    isOpenModal: false,
    user: null
};

const defaultUserType = {
    id: '',
    user_id: 0,
    email: '',
    first_name: '',
    last_name: '',
    image: '',
    profile_image_url: '',
    total_modules_count: 0,
    completed_modules_count: 0,
    training_id: '',
    training_type_key: '',
    training_type_label: '',
    training_title: '',
    training_access_user_id: '',
    due_at: '',
    completed_at: '',
    expires_at: '',
    certificate_url: '',
    user_training_expire_notification_type: '',
}

type FormModalProps = {
    isOpenModal: boolean;
    title: string;
    message: string;
    item: AssignedUserType;
    event: any;
    onYes: (event: any, item: any) => void;
    onNo: (event: any, item: any) => void;
}

// ----------------------------------------------------------------------

export default function TrackCourseTable({
     users,
     formOptions,
     onUserClick,
     onTrackCourseCertificateClick,
     onUpdateUser,
     onUserCompleted,
     onNewCourse
}: Props) {

    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const [assignedUsers, setAssignedUsers] = useState(users);
    const [confirmModalState, setConfirmModalState] = useState(initialConfirmModalState);
    // table
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [filterValues, setFilterValues] = useState(initFilter);
    const filteredUsers = applySortFilter(assignedUsers, getComparator(order, orderBy), filterValues);
    const [formModalState, setFormModalState] = useState<FormModalProps>({
        isOpenModal: false,
        title: '',
        message: '',
        item: defaultUserType,
        event: null,
        onYes: (event: any, item: any) => {},
        onNo: (event: any, item: any) => {}
    });

    const handleRequestSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleFilterChange = (filter: FilterType) => {
        setFilterValues(filter);
    };

    const updateUser = useCallback((user: AssignedUserType) => {
        let found = assignedUsers.find((el) => (el.id.toString() === user.id.toString() && el.training_id.toString() === user.training_id.toString()));

        if (!!found) {
            setAssignedUsers(
                assignedUsers.map(el => ((el.id.toString() === user.id.toString() && el.training_id.toString() === user.training_id.toString()) ? user : el))
            );
        } else {
            setAssignedUsers([...assignedUsers, user]);
        }

        onUpdateUser(user);
    }, [onUpdateUser, assignedUsers]);

    const submitUnassign = async (user: AssignedUserType) => {
        try {
            let url = `/app/shared/training/${user.training_id}/unassign-user`;

            let formData = new FormData();
            formData.append('user_id', user.id);
            formData.append('_method', 'PUT');

            await axios.post(url, formData);

            if (isMountedRef.current) {
                let msg = 'Assignment updated!';

                enqueueSnackbar(msg);
                removeUser(user);
                // reset();
            }
        } catch (error) {
            console.log(error);

            if (isMountedRef.current) {
                let msg = parseBizhavenError(error);

                enqueueSnackbar(msg, {
                    variant: 'error'
                });

                // error.message = msg;
                // setError('afterSubmit', error);
            }
        }
    };

    const onSubmitAssign = useCallback(async (newValues: NewAssignmentType) => {
        try {
            let url = `/app/shared/training/${newValues.training_id}/assign-user`;

            let formData = new FormData();
            formData.append('user_id', newValues.user_id);
            formData.append('due_at', newValues.due_at);

            const response = await axios.post(url, formData);

            if (isMountedRef.current) {
                let msg = 'Assignment updated!';

                let { data } = response.data;

                enqueueSnackbar(msg);
                updateUser(data);
                onUpdateUser(data);
                // reset();
            }
        } catch (error) {
            console.log(error);

            if (isMountedRef.current) {
                let msg = parseBizhavenError(error);

                enqueueSnackbar(msg, {
                    variant: 'error'
                });

                // error.message = msg;
                // setError('afterSubmit', error);
            }
        }
    }, [isMountedRef, enqueueSnackbar, onUpdateUser, updateUser]);

    const onSubmitReminder = async (trainingId: string, userId: string, reminderType: string) => {
        try {
            let url = `/app/shared/training/${trainingId}/user-expire-reminder`;

            let formData = new FormData();
            formData.append('user_id', userId);
            formData.append('type', reminderType);

            const response = await axios.post(url, formData);

            if (isMountedRef.current) {
                let msg = 'Reminder updated!';

                let { data } = response.data;

                enqueueSnackbar(msg);
                updateUser(data);
                onUpdateUser(data);
            }
        } catch (error) {
            console.log(error);

            if (isMountedRef.current) {
                let msg = parseBizhavenError(error);

                enqueueSnackbar(msg, {
                    variant: 'error'
                });

                // error.message = msg;
                // setError('afterSubmit', error);
            }
        }
    };

    const isValidDate = (dateString: string) => {
        let date = new Date(dateString);
        if (isNaN(date.getTime())) {
          return false;
        }

        let parts = dateString.split("-");

        if (parts.length !== 3) {
          return false;
        }

        let [year, month, day] = parts.map(part => parseInt(part, 10));

        if (month < 1 || month > 12 || day < 1 || day > 31 || year < 1000 || year > 9999) {
          return false;
        }

        return true;
    }

    const onDueDateChange = useCallback((_user: AssignedUserType, e: any) => {
        let newDateValue = e.format('YYYY-MM-DD');

        if (!(!!newDateValue) || !isValidDate(newDateValue)) {
            console.log('skipping invalid date');
            return;
        }

        let user = assignedUsers.find(el => (el.training_access_user_id === _user.training_access_user_id))

        if (!!user) {
            let newValues: NewAssignmentType = {
                user_id: user.id,
                training_id: user.training_id,
                due_at: newDateValue
            };

            onSubmitAssign(newValues);
        }
    }, [assignedUsers, onSubmitAssign]);

    // onChange fires on every month change for "date" type input.
    // Adding debounce to decrease the number of server requests
    // https://dmitripavlutin.com/react-throttle-debounce/
    const debouncedDueDateChangeHandler = useMemo(
        () => debounce(onDueDateChange, 1000)
    , [onDueDateChange]);

    const onSubmitCompleted = useCallback(async (completedAt: any, user: AssignedUserType) => {
        try {
            let formData = new FormData();

            formData.append('user_id', user.id);
            formData.append('completed_at', completedAt.format('YYYY-MM-DD'));

            let url = `/app/admin/training/${user.training_id}/mark-user-complete`;

            const response = await axios.post(url, formData);

            if (isMountedRef.current) {
                let msg = 'Complete date updated!';

                let { data } = response.data;

                enqueueSnackbar(msg);

                user.completed_modules_count = user.total_modules_count;
                user.completed_at = data.completed_at;
                user.expires_at = data.expires_at;

                // Not sure why but have to update both of these to 
                //  get the table and parent page to update.
                updateUser(user);
                onUserCompleted(data);
            }
        } catch (error) {
            console.log(error);

            if (isMountedRef.current) {
                let msg = parseBizhavenError(error);

                enqueueSnackbar(msg, { variant: 'error' });
            }
        }
    }, [isMountedRef, enqueueSnackbar, updateUser, onUserCompleted]);

    const onUpdateExpiresAt = async (user: AssignedUserType, newExpiresAt: any) => {
        const url = `/app/admin/training/${user.training_id}/${user.id}/update-expires-at`;
        try {
            const formData = new FormData();
            formData.append('expires_at', newExpiresAt.format('YYYY-MM-DD'));
            formData.append('training_id', user.training_id);
            formData.append('user_id', user.id);
            formData.append('_method', 'PUT');
            const response = await axios.post(url, formData);
            const { data } = response.data;
            user.expires_at = data.expires_at;
            updateUser(user);
            enqueueSnackbar('Expires date updated!');
        } catch (e: any) {
            console.log("ERROR:")
            console.log(e)
            enqueueSnackbar(e.message, { variant: 'error' });
        }
    }

    const onCompletedDateChange = useCallback((_user: AssignedUserType, newDateValue: any) => {

        if (!(!!newDateValue)) {
            return;
        }

        let user = assignedUsers.find(el => (el.training_access_user_id === _user.training_access_user_id))

        if (!!user) {
            onSubmitCompleted(newDateValue, user);
        }
    }, [assignedUsers, onSubmitCompleted]);

    // onChange fires on every month change for "date" type input.
    // Adding debounce to decrease the number of server requests
    // https://dmitripavlutin.com/react-throttle-debounce/
    const debouncedCompleteDateChangeHandler = useMemo(
        () => debounce(onCompletedDateChange, 1000)
    , [onCompletedDateChange]);

    const onReminderChange = (user: AssignedUserType, event: any) => {
        const { target: { value } } = event;

        onSubmitReminder(user.training_id, user.id, value);
    };

    const removeUser = (user: AssignedUserType) => {
        let found = assignedUsers.find((el) => (el.id.toString() === user.id.toString() && el.training_id.toString() === user.training_id.toString()));

        if (!!found) {
            setAssignedUsers(
                assignedUsers.filter(el => (!(el.id.toString() === user.id.toString() && el.training_id.toString() === user.training_id.toString())))
            );
        }
    }

    const getExpiresColor = (dateStr: string) => {
        if (dateIsPassed(dateStr)) {
            return '#FF4842';
        } else {
            return '#212b36';
        }
    }

    const methods = useForm({
    });

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    // OPEN CONFIRMATION MODAL
    const openConfirmationModal = (_user: AssignedUserType) => {
        setConfirmModalState({
            isOpenModal: true,
            user: _user
        });
    }

    // CLOSE CONFIRMATION MODAL
    const closeConfirmationModal = () => {
      setConfirmModalState(initialConfirmModalState);
    }

    const handleConfirmDeleteButton = () => {
        // onDelete(confirmModalState.selectedDepartmentId);
        submitUnassign(confirmModalState.user as AssignedUserType);
        closeConfirmationModal();
    };

    const getFormattedDateForEvent = (date: any) => {
        const inputDate = new Date(date);
        const utcDate = new Date(inputDate.getTime() + inputDate.getTimezoneOffset() * 60000);
        const day = String(utcDate.getUTCDate()).padStart(2, '0');
        const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
        const year = utcDate.getUTCFullYear();
        return `${month}/${day}/${year}`;
    }

    type AssignedTraining = {
        user_id: string|number;
        training_id: string|number;
        date: dayjs.Dayjs | null;
    };

    const [deadlines, setDeadlines] = useState<{
        [key: string]: dayjs.Dayjs | null;
    }>({});
    const [completionDates, setCompletionDates] = useState<{
        [key: string]: dayjs.Dayjs | null;
    }>({});
    const [expiresDates, setExpiresDates] = useState<{
        [key: string]: dayjs.Dayjs | null;
    }>({});

    useEffect( () => {
        const getDeadlines = filteredUsers.map ( (item: AssignedUserType) => {
            return {
                user_id: item.id,
                training_id: item.training_id,
                date: item.due_at ? dayjs(item.due_at) : null
            }
        }).reduce((acc: any, item: AssignedTraining) => {
            acc[`${item.user_id}-${item.training_id}`] = item.date;
            return acc;
        }, {});

        console.log("getDeadlines", getDeadlines)

        const getCompletionDates = filteredUsers.map ( (item: AssignedUserType) => {
            return {
                user_id: item.id,
                training_id: item.training_id,
                date: item.completed_at ? dayjs(item.completed_at) : null
            }
        }).reduce((acc: any, item: AssignedTraining) => {
            acc[`${item.user_id}-${item.training_id}`] = item.date;
            return acc;
        }, {});

        console.log("getCompletionDates", getCompletionDates)

        const getExpiresDates = filteredUsers.map ( (item: AssignedUserType) => {
            return {
                user_id: item.id,
                training_id: item.training_id,
                date: item.expires_at ? dayjs(item.expires_at) : null
            }
        }).reduce((acc: any, item: AssignedTraining) => {
            acc[`${item.user_id}-${item.training_id}`] = item.date;
            return acc;
        }, {});

        console.log("getExpiresDates", getExpiresDates)

        setDeadlines(getDeadlines);
        setCompletionDates(getCompletionDates);
        setExpiresDates(getExpiresDates);
    }, []);

    const onChangeCompletedAtDate = (date: any, dateString: string|string[], item: AssignedUserType) => {
        // The course cannot be modified if not assigned or not completed
        // and is digital, in person can always be modified
        // due_at is the assignation date, indicates whether the course is assigned

        if ((!item.due_at || !item.completed_at) && item.training_type_key === 'digital') {
            enqueueSnackbar(
                `Completion date cannot be changed until the course is completed.`,
                {
                    variant: 'error',
                    autoHideDuration: 10000
                }
            );
            return;
        }

        const utcDateString = date.toDate().toUTCString();
        const newDate = dayjs(utcDateString);
        setCompletionDates({
            ...completionDates,
            [`${item.id}-${item.training_id}`]: newDate
        });
        const formattedDate = getFormattedDateForEvent(date);
        setFormModalState({
            isOpenModal: true,
            item: item,
            message: `Are you sure you want to change the completion date to ${formattedDate}?`,
            title: 'Change completion date',
            event: date,
            onYes: (event: any, item: any) => {
                setCompletionDates({
                    ...completionDates,
                    [`${item.id}-${item.training_id}`]: dayjs(date.toDate().toUTCString())
                });
                onCompletedDateChange(item, date)
            },
            onNo: (event: any, item: any) => {
                // reset to default date
                setCompletionDates({
                    ...completionDates,
                    [`${item.id}-${item.training_id}`]: item.completed_at ? dayjs(item.completed_at) : null
                });
            }
        })
    };


    return (
        <>
            <FormProvider methods={methods}>
                <TrackCourseAssignForm
                    formOptions={formOptions}
                    updateUser={updateUser}
                    onNewCourse={onNewCourse}
                />
            </FormProvider>
            <Box sx={{ marginTop: '11px', marginBottom: '11px' }}>
                <TrackCourseToolbar
                    formOptions={formOptions}
                    onFilterChange={handleFilterChange}
                />
            </Box>

            <FormProvider methods={methods}>
                <Stack>
                    <TableContainer sx={{ minWidth: 720, overflow: 'visible' }}>
                        <Table>
                            <TrackCourseHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody className={'table-body'}>
                                {filteredUsers.length < 1 && (
                                    <TableRow>
                                        <TableCell colSpan={9} style={{textAlign: 'center'}}> No assignments found</TableCell>
                                    </TableRow>
                                )}

                                {filteredUsers
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((item: AssignedUserType) => (
                                        <TableRow key={item.training_access_user_id} className={`certificate-col-${item.first_name}_${item.last_name}`}>
                                            <TableCell sx={{ maxWidth: '170px' }}>
                                                <Button
                                                    startIcon={<Iconify icon={'clarity:pencil-solid'} width={16} height={16} />}
                                                    onClick={() => (onUserClick(item))}
                                                    sx={{ minWidth: '1px' }}
                                                    variant="text"
                                                >
                                                    {item.first_name} {item.last_name}
                                                </Button>
                                            </TableCell>

                                            <TableCell sx={{ maxWidth: '170px' }}>
                                                {item.training_title}
                                            </TableCell>

                                            <TableCell sx={{ maxWidth: '170px' }}>
                                                {item.training_type_label}
                                            </TableCell>

                                            <TableCell>
                                                 <DatePicker
                                                     className={'custom-date-picker'}
                                                        format={'MM/DD/YYYY'}
                                                        allowClear={false}
                                                        defaultValue={item.due_at ? dayjs(item.due_at) : null}
                                                        value={deadlines[`${item.id}-${item.training_id}`]}
                                                        onChange={ (date, dateString) => {
                                                            const utcDateString = date.toDate().toUTCString();
                                                            const newDate = dayjs(utcDateString);
                                                            setDeadlines({
                                                                ...deadlines,
                                                                [`${item.id}-${item.training_id}`]: newDate
                                                            });
                                                            const formattedDate = getFormattedDateForEvent(date);
                                                            setFormModalState({
                                                                isOpenModal: true,
                                                                item: item,
                                                                title: 'Confirm Training Assignment',
                                                                message: `This training will be assigned to ${item.first_name} ${item.last_name} with the deadline set for ${formattedDate}.`,
                                                                event: date,
                                                                onYes: (event: any, item: any) => {
                                                                    setDeadlines({
                                                                        ...deadlines,
                                                                        [`${item.id}-${item.training_id}`]: dayjs(date.toDate().toUTCString())
                                                                    });
                                                                    onDueDateChange(item, date)
                                                                },
                                                                onNo: (event: any, item: any) => {
                                                                    // reset to default date
                                                                    setDeadlines({
                                                                        ...deadlines,
                                                                        [`${item.id}-${item.training_id}`]: item.due_at ? dayjs(item.due_at) : null
                                                                    });
                                                                }
                                                            })
                                                        }}
                                                    />
                                            </TableCell>

                                            <TableCell>
                                                <DatePicker
                                                    className={'custom-date-picker'}
                                                    format={'MM/DD/YYYY'}
                                                    allowClear={false}
                                                    defaultValue={item.completed_at ? dayjs(item.completed_at) : null}
                                                    value={completionDates[`${item.id}-${item.training_id}`]}
                                                    onChange={(date, dateString) => onChangeCompletedAtDate(date, dateString, item)}
                                                />
                                            </TableCell>

                                            <TableCell>
                                                { item.expires_at ? dayjs(item.expires_at).format('MM-DD-YYYY') : '-' }
                                            </TableCell>

                                            <TableCell>
                                                { (!item.certificate_url) && (
                                                    <IconButton
                                                        size="large"
                                                        color="primary"
                                                        onClick={() => (onTrackCourseCertificateClick(item))}
                                                    >
                                                        <Iconify icon={'akar-icons:minus'} width={25} height={25} />
                                                    </IconButton>
                                                )}
                                                { item.certificate_url && (
                                                    <IconButton
                                                        size="large"
                                                        color="primary"
                                                        onClick={() => (onTrackCourseCertificateClick(item))}
                                                    >
                                                        <Iconify icon={'clarity:certificate-line'} color="#56397b" width={22} height={22} />
                                                    </IconButton>
                                                )}
                                            </TableCell>

                                            <TableCell>
                                                <RHFSelect
                                                    InputLabelProps={{ shrink: true }}
                                                    sx={{ maxWidth: '250px' }}
                                                    name="type"
                                                    label="Remind Me"
                                                    defaultValue={item.user_training_expire_notification_type}
                                                    placeholder=""
                                                    onChange={(e) => onReminderChange(item, e)}
                                                >
                                                    <option key="" value="">No Reminder</option>

                                                    {formOptions.reminder_options.map((option: any) => (
                                                        <option key={option.key} value={option.key}>
                                                            {option.label}
                                                        </option>
                                                    ))}
                                                </RHFSelect>
                                            </TableCell>

                                            <TableCell>
                                                <IconButton 
                                                    size="large" 
                                                    color="error" 
                                                    onClick={() => (openConfirmationModal(item))}
                                                >
                                                    <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    { formModalState.isOpenModal && <>
                        <ConfirmationDialog
                            title={formModalState.title}
                            message={formModalState.message}
                            open={formModalState.isOpenModal}
                            onCloseConfirm={ async (confirmed: boolean) => {
                                if (confirmed) await formModalState.onYes(formModalState.event, formModalState.item);
                                if (!confirmed) await formModalState.onNo(formModalState.event, formModalState.item);
                                setFormModalState({
                                    isOpenModal: false,
                                    item: defaultUserType,
                                    title: '',
                                    message: '',
                                    event: '',
                                    onYes: (event: any, item: any) => {},
                                    onNo: (event: any, item: any) => {}
                                })
                            }}
                        />
                    </>}

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={assignedUsers.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={(e, page) => setPage(page)}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Stack>
            </FormProvider>

            <DialogAnimate open={confirmModalState.isOpenModal} onClose={closeConfirmationModal}>
                <DialogTitle sx={{ mb: 1 }}>Delete Assignment</DialogTitle>
                <Typography sx={{ ml: 3 }} color="text.secondary">Are you sure you want to remove this training assignment?</Typography>

                <DialogActions>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                    <Button variant="outlined" color="inherit" onClick={closeConfirmationModal}>
                        No
                    </Button>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                    <Button
                        color="error"
                        type="submit"
                        variant="contained"
                        onClick={handleConfirmDeleteButton}
                    >
                        Yes
                    </Button>
                    </Grid>
                </Grid>
                </DialogActions>
            </DialogAnimate>
        </>
    );
}

// ----------------------------------------------------------------------

type Anonymous = Record<string | number, string>;

function descendingComparator(a: Anonymous, b: Anonymous, orderBy: string) {
    if (orderBy === 'name') {
        let aname = a.first_name + ' ' + a.last_name;
        let bname = b.first_name + ' ' + b.last_name;

        if (bname < aname) {
            return -1;
        } else if (bname > aname) {
            return 1;
        } else {
            return 0;
        }
    }

    // check for null or undefined
    if (!(!!b[orderBy]) && !(!!a[orderBy])) {
        return 0;
    } else if (!(!!b[orderBy])) {
        return -1;
    } else if (!(!!a[orderBy])) {
        return 1;
    }

    if (b[orderBy] < a[orderBy]) {
        return -1;
    } else if (b[orderBy] > a[orderBy]) {
        return 1;
    } else {
        return 0;
    }
}

function getComparator(order: string, orderBy: string) {
    return order === 'desc'
        ? (a: Anonymous, b: Anonymous) => descendingComparator(a, b, orderBy)
        : (a: Anonymous, b: Anonymous) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(
    array: AssignedUserType[],
    comparator: (a: any, b: any) => number,
    filter: FilterType
) {
    const { query } = filter;
    const trainingId: string = filter.training_id;
    const dueAt: string = filter.due_at;
    let stabilizedThis: any = [];

    stabilizedThis = array.map((el, index) => [el, index] as const);

    // filter
    if (query) {
        const filteredArray = stabilizedThis.filter((item: any) => {
            const _user: any = item[0];

            const name = _user.first_name + ' ' + _user.last_name;
            // const trainingTitle = _user.training_title;

            return name.toLowerCase().indexOf(query.toLowerCase()) !== -1;

            // search training title
            // || trainingTitle.toLowerCase().indexOf(query.toLowerCase()) !== -1;
        });

        stabilizedThis = filteredArray;
    }
    if (trainingId) {
        const filteredArray = stabilizedThis.filter((item: any) => {
            let _user: any = item[0];

            return _user.training_id.toString() === trainingId.toString();
        });

        stabilizedThis = filteredArray;
    }
    if (dueAt) {
        let nowDate: string = moment().format('YYYY-MM-DD');
        let compareDate: string = '';

        if (dueAt === 'next_30_days') {
            compareDate = moment().add(30, 'day').format('YYYY-MM-DD');
        } else if (dueAt === 'next_90_days') {
            compareDate = moment().add(90, 'day').format('YYYY-MM-DD');
        } else if (dueAt === 'next_180_days') {
            compareDate = moment().add(180, 'day').format('YYYY-MM-DD');
        } else if (dueAt === 'next_365_days') {
            compareDate = moment().add(365, 'day').format('YYYY-MM-DD');
        } else {
            console.log('Unknown due at filter');
        }

        const filteredArray = stabilizedThis.filter((item: any) => {
            let _user: any = item[0];

            return _user.due_at >= nowDate && _user.due_at <= compareDate;
        });

        stabilizedThis = filteredArray;
    }

    // sort
    stabilizedThis.sort((a: any, b: any) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });

    return stabilizedThis.map((el: any) => el[0]);
}
