import { useLocation, useNavigate } from 'react-router-dom';
import { useRef, useEffect, useState } from 'react';
// @mui
import {
  Card,
  ImageList,
  ImageListItem,
  Box,
  Button,
  Typography,
  CardActionArea,
  CardContent,
  Stack,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Label from '../../../components/Label';
import Image from '../../../components/Image';
import Iconify from '../../../components/Iconify';
// config
import { COURSE_TYPES, ROLE } from '../../../config';
// @types
import { TrainingCourseType } from '../../../@types/user';
// hooks
import useAuth from '../../../hooks/useAuth';
// util
import { dateIsPassed, fDateShorter } from '../../../utils/formatTime';

// ----------------------------------------------------------------------

type Props = {
  courses: TrainingCourseType[];
};

// ----------------------------------------------------------------------

export default function LearningCourseScrollMenu({ courses }: Props) {
  let navigate = useNavigate();
  let location = useLocation();
  const { user } = useAuth();
  const [isOverflowing, setIsOverflowing] = useState(false);
  const widthRef = useRef(null);

  useEffect(() => {
    const el = widthRef.current;
    if (el !== null) {
      // @ts-ignore
      if (el.offsetWidth < el.scrollWidth) {
        setIsOverflowing(true);
      }
    }
  }, []);

  const onClick = (course: TrainingCourseType) => {
    let path = '';

    if (user && user.role_id.toString() === ROLE.ADMIN) {
      if (location.pathname.includes('admin')) {
        path = `/admin/learning/${course.id}`;
      } else {
        path = `/employee/learning/${course.id}`;
      }
    } else {
      path = `/employee/learning/${course.id}`;
    }

    // add a delay to allow the ripple animation to finish
    setTimeout(() => {
      navigate(path, { replace: false });
    }, 600);
  };

  const scrollForward = () => {
    const el = widthRef.current;
    if (el !== null) {
      // @ts-ignore
      el?.scrollBy({
        top: 0,
        left: 325,
        behavior: 'smooth',
      });
    }
  };

  const scrollBack = () => {
    const el = widthRef.current;
    if (el !== null) {
      // @ts-ignore
      el?.scrollBy({
        top: 0,
        left: -325,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <ImageList
        ref={widthRef}
        sx={{
          gridAutoFlow: 'column',
          gridTemplateColumns: 'repeat(auto-fill, minmax(325px, 1fr)) !important',
          gridAutoColumns: 'minmax(325px, fr)',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {courses.map((course) => (
          <ImageListItem key={course.id} className={"course-element"} id={"course-element"}>
            {user && user.role_id.toString() === ROLE.ADMIN && (
              <AdminCourseCard course={course} onClick={onClick} />
            )}
            {user && user.role_id.toString() === ROLE.EMPLOYEE && (
              <EmployeeCourseCard course={course} onClick={onClick} />
            )}
          </ImageListItem>
        ))}
      </ImageList>

      {isOverflowing && (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Button variant="contained" color="inherit" onClick={scrollBack}>
            <Iconify icon={'material-symbols:chevron-left'} height={25} width={25} />
          </Button>

          <Box sx={{ ml: 1, alignSelf: 'end' }}>
            <Button variant="contained" color="inherit" onClick={scrollForward}>
              <Iconify icon={'material-symbols:chevron-right'} height={25} width={25} />
            </Button>
          </Box>
        </Stack>
      )}
    </>
  );
}

type AdminCourseCardProps = {
  course: TrainingCourseType;
  onClick: Function;
};
function AdminCourseCard({ course, onClick }: AdminCourseCardProps) {
  const theme = useTheme();

  return (
    <Card sx={{ maxWidth: 345, mb: 2, mr: 2, flexGrow: 1 }}>
      <CardActionArea onClick={() => onClick(course)}>
        <Image
          alt={'Course'}
          src={course.cover_image_url}
          ratio="16/9"
          sx={{ minWidth: '300px' }}
        />

        <CardContent>
          {course.type_key !== COURSE_TYPES.IN_PERSON && (
            <Label
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color={'primary'}
              sx={{ mr: 1, mb: 1 }}
            >
              Modules: {course.modules_count}
            </Label>
          )}

          {course.type_key !== COURSE_TYPES.IN_PERSON && (
            <Label
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color={'primary'}
              sx={{ mr: 1, mb: 1 }}
            >
              Quizzes: {course.quizzes_count}
            </Label>
          )}

          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={'primary'}
            sx={{ mr: 1, mb: 1 }}
          >
            Employees: {course.employees_count}
          </Label>

          <Typography
            gutterBottom
            component="div"
            variant="subtitle2"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              mb: 0,
            }}
          >
            {course.title}
          </Typography>

          <Typography
            gutterBottom
            component="div"
            variant="subtitle2"
            sx={{
              fontSize: '14px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: 'text.disabled',
            }}
          >
            {course.type_label}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

type EmployeeCourseCardProps = {
  course: TrainingCourseType;
  onClick: Function;
};
function EmployeeCourseCard({ course, onClick }: EmployeeCourseCardProps) {
  const theme = useTheme();

  const getExpiresColor = (dateStr: string) => {
    if (dateIsPassed(dateStr)) {
      return 'error';
    } else {
      return 'default';
    }
  };

  return (
    <Card sx={{ maxWidth: 345, mb: 2, mr: 2, flexGrow: 1 }}>
      <CardActionArea onClick={() => onClick(course)}>
        <Image
            alt={'Course'}
            src={course.cover_image_url}
            ratio="16/9"
            sx={{ minWidth: '300px' }}
        />

        <CardContent>
          {!!course.user_did_complete && (
              <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color={'success'}
                  sx={{ mr: 1, mb: 1 }}
              >
                Completed
                {!!course.certificate_url && (
                    <Iconify icon={'clarity:certificate-line'} width={16} height={16} sx={{ ml: 1 }} />
                )}
              </Label>
          )}

          {!!!course.user_did_complete && (
              <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color={'error'}
                  sx={{ mr: 1, mb: 1 }}
              >
                Pending
              </Label>
          )}

          {course.type_key !== COURSE_TYPES.IN_PERSON && (
              <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color={'primary'}
                  sx={{ mr: 1, mb: 1 }}
              >
                Modules: {course.modules_count}
              </Label>
          )}

          {course.type_key !== COURSE_TYPES.IN_PERSON && (
              <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color={'primary'}
                  sx={{ mr: 1, mb: 1 }}
              >
                Quizzes: {course.quizzes_count}
              </Label>
          )}

          {!!course.expires_at && (
              <Label
                  variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                  color={getExpiresColor(course.expires_at)}
                  sx={{ mr: 1, mb: 1 }}
              >
                Expires: {fDateShorter(course.expires_at)}
              </Label>
          )}

          {!!course.due_at && !!!course.completed_at && (
              <Box>
                <Typography
                    gutterBottom
                    variant="caption"
                    sx={{
                      color: 'text.disabled',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 'bold',
                    }}
                >
                  Due: {fDateShorter(course.due_at)}
                </Typography>
              </Box>
          )}

          {!!course.completed_at && (
              <Box>
                <Typography
                    gutterBottom
                    variant="caption"
                    sx={{
                      color: 'text.disabled',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 'bold',
                    }}
                >
                  Completed: {fDateShorter(course.completed_at)}
                </Typography>
              </Box>
          )}

          <Box>
            <Typography
                gutterBottom
                variant="subtitle2"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
            >
              {course.title}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
