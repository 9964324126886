import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
// @mui
import { Card, Typography, Box, Stack, Button } from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
import { useTheme } from '@mui/material/styles';
// hooks
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
//util
import { parseBizhavenError } from '../../../../utils/parseError';

// ----------------------------------------------------------------------

type OnboardingDoc = {
    id: number;
    created_by: number;
    custom_doc_user_id: number | null;
    company_id: number;
    name: string;
    is_onboarding: number;
    get_file_url: string;
    created_at: string;
    updated_at: string;
}

type Props = {
    doc: OnboardingDoc;
    onClick: (doc: OnboardingDoc) => void;
};

// ----------------------------------------------------------------------

export default function OnboardingDocCard({ doc, onClick }: Props) {
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const { name, get_file_url } = doc;

    const handleDownload = useCallback(async (item: OnboardingDoc) => {
        try {
            let url = item.get_file_url;
            const response = await axios.post(url);

            if (isMountedRef.current) {
                let { url } = response.data.data;
                window.open(url, '_blank');
            }
        } catch (err) {
            console.log(err);

            let msg = parseBizhavenError(err);
            enqueueSnackbar(msg, { variant: 'error' });
        }
    }, [isMountedRef, enqueueSnackbar]);

    return (
        <Card sx={{ ...MY_STYLE.card }}>
            <Stack direction="row">
                <Box>
                    <Box sx={{ ml: 1, mt: 1, textAlign: 'center' }}>
                        <Typography variant="subtitle1">{name}</Typography>
                        <Typography variant="body2" sx={{ opacity: 0.72 }}>Onboarding Document</Typography>
                    </Box>
                    <Box sx={{ ml: 1, mt: 1, textAlign: 'center' }}>
                        {get_file_url && (
                            <Box>
                                <Typography variant="body2" sx={{ mb: 1, textAlign: 'center', fontStyle: 'italic' }}>
                                    <Iconify icon={'bi:check-circle-fill'} sx={{ verticalAlign: 'middle', color: theme.palette.success.main }} /> Completed
                                </Typography>

                                <Button
                                    variant="outlined"
                                    sx={{ mt: 1 }}
                                    startIcon={<Iconify icon={'akar-icons:download'} />}
                                    onClick={() => { handleDownload(doc) }}
                                >
                                    Download
                                </Button>
                            </Box>
                        )}
                        {!get_file_url && (
                            <Box>
                                <Typography variant="body2" sx={{ mb: 1, textAlign: 'center', fontStyle: 'italic' }}>
                                    <Iconify icon={'bi:exclamation-circle-fill'} sx={{ verticalAlign: 'middle', color: theme.palette.error.main }} /> Not Completed
                                </Typography>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 1 }}
                                    onClick={() => onClick(doc)}
                                >
                                    <Iconify icon={'mdi:file-document'} sx={{ verticalAlign: 'middle', mr: 1 }} />
                                    Complete
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Stack>
        </Card>
    );
}

const MY_STYLE = {
    card: {
        padding: '30px',
        display: 'flex' as 'flex',
        position: 'relative' as 'relative',
        alignItems: 'center' as 'center',
        flexDirection: 'column' as 'column',
        height: '100%'
    },
    avatar_style: {
        mx: 'auto',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: 'common.white',
        width: { xs: 80, md: 75 },
        height: { xs: 80, md: 75 },
    },
    location_from: {
        width: '75px',
        whiteSpace: 'nowrap' as 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    video_container: {
        position: 'relative' as 'relative',
        display: 'block',
        width: '100%',
        padding: '0',
        overflow: 'hidden',
        paddingBottom: '56.25%',
        marginBottom: '10px',
        backgroundColor: '#f5f5f7'
    },
    video_iframe: {
        position: 'absolute' as 'absolute',
        top: '0',
        bottom: '0',
        left: '0',
        width: '100%',
        height: '100%',
        border: '0',
    }
}