// @mui
import { styled } from '@mui/material/styles';
import { Stack, Typography, IconButton } from '@mui/material';
// utils
import { fullMonthDate } from '../../../../utils/formatTime';
// @types
import { CalendarView } from '../../../../@types/calendar';
// components
import Iconify from '../../../../components/Iconify';

// ----------------------------------------------------------------------

// const VIEW_OPTIONS = [
//   { value: 'dayGridMonth', label: 'Month', icon: 'ic:round-view-module' },
//   // { value: 'timeGridWeek', label: 'Week', icon: 'ic:round-view-week' },
//   // { value: 'timeGridDay', label: 'Day', icon: 'ic:round-view-day' },
//   // { value: 'listWeek', label: 'Agenda', icon: 'ic:round-view-agenda' },
// ] as const;

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(2.5),
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
    justifyContent: 'center',
  },
}));

// ----------------------------------------------------------------------

type Props = {
  date: Date;
  view: CalendarView;
  onToday: VoidFunction;
  onNextDate: VoidFunction;
  onPrevDate: VoidFunction;
};

export default function CalendarToolbar({ date, onNextDate, onPrevDate }: Props) {

  return (
    <RootStyle>
      <Stack direction="row" alignItems="center" spacing={2}>
        <IconButton onClick={onPrevDate}>
          <Iconify icon="eva:arrow-ios-back-fill" width={20} height={20} />
        </IconButton>

        <Typography variant="h5">{fullMonthDate(date)}</Typography>

        <IconButton onClick={onNextDate}>
          <Iconify icon="eva:arrow-ios-forward-fill" width={20} height={20} />
        </IconButton>
      </Stack>

      {/* {isDesktop && (
        <Button size="small" color="primary" variant="contained" onClick={onToday}>
          Today
        </Button>
      )} */}
    </RootStyle>
  );
}
