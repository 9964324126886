import { useState, useCallback, useEffect } from 'react';
import axios from 'src/utils/axios';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import { BaseOptionChart } from '../../../components/chart';
import { SkeletonGraph } from '../../../components/skeleton';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

// ----------------------------------------------------------------------

const chartDataPointToSearchFilter = ['25', '50', '75','100'];

export default function AdminTimeOffChart() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMountedRef = useIsMountedRef();
  const [chartData, setChartData] = useState({ labels: [], values: [] });

  const getTimeOffBalanceInfo = useCallback(async () => {
    try {
      const response = await axios.get('/app/admin/dashboard/time-off/balance/chart', {
        params: {  },
      });

      if (isMountedRef.current) {
        let { data } = response.data;
        
        data.values = data.values.map((item: any) => parseFloat(item))

        setChartData(data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getTimeOffBalanceInfo();
  }, [getTimeOffBalanceInfo]);

  const goToTimeOfBalancePage = (filter: string) => {
    let url = `/admin/time-off?type=balance&filter=${filter}`;
    navigate(url, { replace: true });
  }

  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      theme.palette.primary.dark,
      theme.palette.primary.main,
      theme.palette.primary.light,
      theme.palette.primary.lighter,
      theme.palette.primary.dark,
      theme.palette.primary.main,
      theme.palette.primary.light,
      theme.palette.primary.lighter
    ],
    labels: chartData.labels,
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '85%',
          labels: {
            value: {
              formatter: (val: number | string) => fNumber(val) + ' Employees'
            },
            total: {
              formatter: (w: { globals: { seriesTotals: number[] } }) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return fNumber(sum) + ' Employees';
              },
            },
          },
        },
      },
    },
    chart: {
      events: {
        dataPointSelection: (event: any, chartContext: any, config: any) => {
          let dataPointIndex = parseInt(config.dataPointIndex);
          let filter = '';
          if(dataPointIndex in chartDataPointToSearchFilter){
            filter = chartDataPointToSearchFilter[dataPointIndex];
          }

          // 2022.09.29: idk why but this is throwing an error
          // https://github.com/apexcharts/apexcharts.js/issues/2258
          goToTimeOfBalancePage(filter);
        }
      }
    }
  });

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title="Time-Off Balances" />

      { chartData.labels.length ? (
        <ChartWrapperStyle dir="ltr">
          <ReactApexChart type="donut" series={chartData.values} options={chartOptions} height={280} />
        </ChartWrapperStyle>
        ) : (
          <SkeletonGraph />
        )
      }
    </Card>
  );
}
