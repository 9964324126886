import { useFormContext, Controller } from 'react-hook-form';
import {
  FormControl,
  Autocomplete,
  TextField,
} from '@mui/material';
import { UserOption } from 'src/@types/user';


interface IProps {
  name: string;
  label: string;
  options: UserOption[];
  optionLabel: Function;
  renderValue: string;
  value: any;
  onChange: any;
  multipleSelection?: boolean;
}

export default function RHFAutocomplete(
    {
      name,
      label,
      options,
      value,
      onChange,
      optionLabel,
      renderValue,
      multipleSelection=false
    }: IProps
) {
  const { control } = useFormContext();

  return (
      <Controller
          name={name}
          control={control}
          render={({ field }) => (
              <FormControl fullWidth>
                <Autocomplete
                    {...field}
                    fullWidth
                    options={options}
                    value={value || undefined || null}
                    defaultValue={null}
                    onChange={onChange}
                    multiple={multipleSelection}
                    disableCloseOnSelect={multipleSelection}
                    disableClearable={!multipleSelection}
                    getOptionLabel={optionLabel()}
                    renderInput={(params) => (
                        <TextField {...params} label={label}/>
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option.id}>
                        {option[renderValue]}
                      </li>
                    )}
                />
              </FormControl>
          )}
      />
  );
}
