import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function dateIsPassed(date: Date | string | number){
  var dateToCompare = new Date(date)
  var today = new Date();

  return dateToCompare < today;
}

export function fullMonthDate(date: Date | string | number) {
  return format(new Date(date), 'MMMM yyyy');
}

export function mDate(date: Date | string | number) {
  return format(new Date(date), 'MMM yyyy');
}

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateWordShort(date: Date | string | number) {
  // undoing conversion from UTC to local time before format()
  // https://stackoverflow.com/a/52352512

  const dt = new Date(date);
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
  const dateStr = format(dtDateOnly, 'MMM d, yyyy')

  return dateStr;
  // return format(new Date(date), 'M/d');
}

export function fDateVeryShort(date: Date | string | number) {
  // undoing conversion from UTC to local time before format()
  // https://stackoverflow.com/a/52352512

  const dt = new Date(date);
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);
  const dateStr = format(dtDateOnly, 'M/d')

  return dateStr;
  // return format(new Date(date), 'M/d');
}

export function fDateShorter(date: Date | string | number) {
  // undoing conversion from UTC to local time before format()
  // https://stackoverflow.com/a/52352512

  const dt = new Date(date);
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

  try{
    const dateStr = format(dtDateOnly, 'MM/dd/yy')
    return dateStr;
  }catch(e){
    // this is probably caused by Safari not liking various date formats
    console.log('date format failed for ', date);
  }

  // return format(new Date(date), 'M/d');
}

export function fDateShort(date: Date | string | number) {
  // undoing conversion from UTC to local time before format()
  // https://stackoverflow.com/a/52352512

  const dt = new Date(date);
  const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

  try{
    const dateStr = format(dtDateOnly, 'MM/dd/yyyy');
    return dateStr;
  }catch(e){
    // this is probably caused by Safari not liking various date formats
    console.log('date format failed for ', date);
  }

  return '';
  // return format(new Date(date), 'MM/dd/yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  // Note: this converts from UTC to device timezone
  return format(new Date(date), 'MM/dd/yyyy h:mm a');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function fTime(time: string) {
  const timeSplited = time.split(':');
  
  return `${timeSplited[0]}:${timeSplited[1]}`;
}
