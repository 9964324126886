import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import { useState, useCallback, useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { 
  Box, Stack, Button, Alert, Link, 
  Typography, DialogActions,Tooltip,IconButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFSelect, RHFUploadSingleFile } from '../../../components/hook-form';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// util
import { parseBizhavenError } from '../../../utils/parseError';

// ----------------------------------------------------------------------

const getInitialValues = (benefit: any) => {
  let _benefit = {
    benefit_type_id: '',
    title: '',
    dollar_amount: 0,
    description: '',
    link: '',
    file: null,
    afterSubmit: ''
  };

  if(benefit != null && benefit.benefit_type_id){
    _benefit = {
      benefit_type_id: benefit.benefit_type_id,
      title: benefit.title,
      dollar_amount: benefit.dollar_amount,
      description: benefit.description,
      link: benefit.link ? benefit.link : '',
      file: null,
      afterSubmit: ''
    };
  }

  return _benefit;
};

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

type FormValuesProps = {
  benefit_type_id: string;
  title: string;
  dollar_amount: number;
  description: string;
  link: string;
  file: File | any;
};

type Props = {
  onCancel: VoidFunction;
  benefit: FormValuesProps|null;
  benefit_id: string|null;
  benefit_file_url: string|null;
};

export default function BenefitForm({ onCancel, benefit, benefit_id, benefit_file_url }: Props) {
  const [formOptions, setFormOptions] = useState({ types: [] });
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const EventSchema = Yup.object().shape({
    benefit_type_id: Yup.string().max(5000).required('Type is required'),
    title: Yup.string().max(5000).required('Title is required'),
    dollar_amount: Yup.number().required('Amount is required'),
    description: Yup.string().max(5000).required('Description is required'),
    link: Yup.string().max(5000),
    file: Yup.mixed()
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(benefit)
  });

  const {
    reset,
    setValue,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const getFormOptions = useCallback(async () => {
    try {
      const response = await axios.get('/app/admin/benefit/form-options', {
        params: {  },
      });

      if (isMountedRef.current) {
        let { data } = response.data;
        
        setFormOptions(data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getFormOptions();
  }, [getFormOptions]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'file',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const onSubmit = async (data: FormValuesProps) => {
    try {
      var formData = new FormData();

      formData.append("benefit_type_id", data.benefit_type_id);
      formData.append("title", data.title);
      formData.append("dollar_amount", data.dollar_amount.toString());
      formData.append("description", data.description);
      formData.append("link", data.link);

      if(data.file){
        formData.append("file", data.file);
      }
      
      let url = '/app/admin/benefit';

      // switch to update endpoint
      if(benefit_id){
        url += '/' + benefit_id
        formData.append('_method', 'PUT');
      }

      await axios.post(url, formData);

      if (isMountedRef.current) {
        let msg = 'Benefit updated!';

        enqueueSnackbar(msg);
        onCancel();
        reset();
      }
    } catch (error) {
      console.log(error);

      if (isMountedRef.current) {
        let message = parseBizhavenError(error);
        error.message = message;
        
        setError('afterSubmit', error);
      }
    }
  };

  const onDelete = async() => {
    try {
      let url = '/app/admin/benefit/' + benefit_id
      let data = {
        '_method': 'DELETE'
      };

      await axios.post(url, data);

      if (isMountedRef.current) {
        let msg = 'Benefit deleted!';

        enqueueSnackbar(msg);
        onCancel();
        reset();
      }
    } catch (error) {
      console.log(error);

      if (isMountedRef.current) {
        let message = parseBizhavenError(error);
        error.message = message;
        
        setError('afterSubmit', error);
      }
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <RHFSelect 
          name="benefit_type_id" 
          label="Type" 
          placeholder="Type"
          InputLabelProps={{ shrink: true }}
        >
          <option value="" />
          {formOptions.types.map((item: any) => (
            <optgroup key={item.category.id} label={item.category.label}>
              {item.types.map((type: any) => (
                <option key={type.id} value={type.id}>
                  {type.label}
                </option>
              ))}
            </optgroup>
          ))}
        </RHFSelect>

        <RHFTextField name="title" label="Title" />

        <RHFTextField type="number" name="dollar_amount" label="Dollar Amount" />

        <RHFTextField name="description" label="Description" multiline rows={4} />

        <RHFTextField name="link" label="Link" />

        {benefit_file_url &&(
          <Link 
            href={benefit_file_url}
            target="_blank"
          >
            <Button variant="outlined" color="inherit">
              View Current File
            </Button>
          </Link>
        )}

        <div>
          <LabelStyle>File (pdf)</LabelStyle>
          <RHFUploadSingleFile
            name="file"
            accept=".pdf"
            maxSize={5242880}
            onDrop={handleDrop}
          />
        </div>

        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      </Stack>

      <DialogActions>
        {benefit_id && (
          <Tooltip title="Delete Event">
            <IconButton onClick={onDelete} color="error">
              <Iconify icon="eva:trash-2-outline" width={20} height={20} />
            </IconButton>
          </Tooltip>
        )}
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting}
          loadingIndicator="Loading..."
        >
          Update
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
