import { format } from 'date-fns';
// @mui
import {
    TableRow, TableCell, 
    Typography, IconButton, Button,
    Box
} from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
// types
import { DocumentCategoryType, DocumentType } from '../../../../@types/user';
import { useCallback, useEffect, useState } from 'react';
import { OnboardingDocFormDialog } from 'src/sections/employee/onboard/onboarding-docs';
import { useTheme } from '@mui/material/styles';
import axios from "../../../../utils/axios";
import {parseBizhavenError} from "../../../../utils/parseError";
import useAuth from "../../../../hooks/useAuth";
import {ROLE} from "../../../../config";



// ----------------------------------------------------------------------

type OnboardingDoc = {
    id: string;
    created_by: string;
    custom_doc_user_id?: number | null;
    company_id: string;
    name: string;
    is_onboarding: number;
    get_file_url: string;
    created_at: string;
    updated_at: string;
    size?: string;
    employee_name?: string;
    custom_document?: any;
    path?: string;
    user_id?: number;
}

type Props = {
    doc: DocumentType;
    onEditClick: Function;
    opDeleteClick: Function;
    onDownloadClick: Function;
    onSelectDoc?: Function | null;
    selectedCategory?: DocumentCategoryType;
};

type FormModalState = {
    isOpenModal: boolean;
    doc: OnboardingDoc | null;
  };
  
  const initialFormModalState: FormModalState = {
    isOpenModal: false,
    doc: null
  };
  

// ----------------------------------------------------------------------

export default function DocumentsTable({ doc, onEditClick, onDownloadClick, opDeleteClick, onSelectDoc=null, selectedCategory }: Props) {

    const [formModalState, setFormModalState] = useState(initialFormModalState);
    const closeFormModal = useCallback((_doc: OnboardingDoc | null) => {
        setFormModalState(initialFormModalState);
        window.location.reload();
    }, []);
    const theme = useTheme();
    const { isAuthenticated, user } = useAuth();
    const handleCompleteDoc = useCallback((_doc: OnboardingDoc) => {
        setFormModalState({
          isOpenModal: true,
          doc: _doc
        });
    }, [setFormModalState]);
    const isAdmin = (user: any) => user.role_id == ROLE.ADMIN;

    return (
        <TableRow
            key={doc.id}
            sx={{ 'td': { borderBottom: 'solid 1px #f4f6f8' } }}
        >
            <TableCell>
                <Typography 
                    variant="body2"
                    // onClick={() => { onDownloadClick(doc) }}
                    onClick={() => handleCompleteDoc(doc)}
                    sx={{ cursor: 'pointer' }}
                >
                    {doc.name}
                </Typography>
            </TableCell>
            {
                (selectedCategory?.name === 'Pending') && (
                    <TableCell>
                        <Typography variant="body2">{ doc.employee_name}</Typography>
                    </TableCell>
                )
            }
            {
                (selectedCategory?.name === 'HR' || selectedCategory?.name === 'Safety') &&
                <TableCell>
                    <Typography variant="body2">{doc.size}</Typography>
                </TableCell>
            }

            <TableCell>
                {format(new Date(doc.created_at), 'MMM dd, yyyy')}
            </TableCell>

            {(onSelectDoc === null) && (
                <TableCell align="right" sx={{ width: '230px' }}>
                    {(!!doc.company_id) && (
                        <IconButton
                            color="info"
                            aria-label="edit"
                            onClick={() => onEditClick(doc)}
                        >
                            <Iconify icon={'akar-icons:pencil'} />
                        </IconButton>
                    )}

                    {(!!doc.company_id) && (
                        <IconButton
                            color="error"
                            aria-label="delete"
                            onClick={() => { opDeleteClick(doc) }}
                        >
                            <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                    )}

                    { selectedCategory?.name === 'Pending' &&
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{ mt: 1 }}
                                onClick={() => handleCompleteDoc(doc)}
                                style={{
                                    fontSize: '13px'
                                }}
                            >
                                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
                                    marginRight: '5px'
                                }}>
                                    <g id="ErrorOutline">
                                        <path id="Vector" d="M8.9375 11.25H10.4375V12.75H8.9375V11.25ZM8.9375 5.25H10.4375V9.75H8.9375V5.25ZM9.68 1.5C5.54 1.5 2.1875 4.86 2.1875 9C2.1875 13.14 5.54 16.5 9.68 16.5C13.8275 16.5 17.1875 13.14 17.1875 9C17.1875 4.86 13.8275 1.5 9.68 1.5ZM9.6875 15C6.3725 15 3.6875 12.315 3.6875 9C3.6875 5.685 6.3725 3 9.6875 3C13.0025 3 15.6875 5.685 15.6875 9C15.6875 12.315 13.0025 15 9.6875 15Z" fill="white"/>
                                    </g>
                                </svg>

                                Action Required
                            </Button>
                        </Box>
                    }

                    {(formModalState.isOpenModal && !!formModalState.doc) && <>
                        <OnboardingDocFormDialog
                            doc={{
                                id: formModalState.doc.id as any,
                                user_id: formModalState.doc.user_id as any,
                                created_by: formModalState.doc.created_by as any,
                                custom_doc_user_id: formModalState.doc.custom_doc_user_id as any,
                                company_id: formModalState.doc.company_id as any,
                                name: formModalState.doc.name,
                                is_onboarding: formModalState.doc.is_onboarding,
                                get_file_url: formModalState.doc.get_file_url,
                                created_at: formModalState.doc.created_at,
                                updated_at: formModalState.doc.updated_at,
                                custom_document: formModalState.doc.custom_document,
                                path: formModalState.doc.path
                            }}
                            isOpen={formModalState.isOpenModal}
                            onCancel={() => closeFormModal(null)}
                            onComplete={ () => closeFormModal(null)}
                            postUrl={
                                // if the document is assigned to the admin, then the route should be
                                // the same as for the employee

                                // if the admin is not assigned to the document, then the route should be
                                // the route to store the company sign
                                user && formModalState.doc.user_id === user.id
                                ? null
                                : `app/admin/custom-doc-user/${formModalState.doc.id}/store-company-sign`
                            }
                            signType={user && formModalState.doc.user_id === user.id ? 'employee' : 'company'}
                        />
                    </>}

                    {
                        selectedCategory?.name != 'Pending' && <IconButton
                            aria-label="view"
                            onClick={ async () => {
                                try {
                                    if (doc.type_document === 'document') {
                                        onDownloadClick(doc, 'view')
                                    }

                                    if (doc.type_document === 'custom_doc') {
                                        let url = `/app/admin/custom-doc/${doc.id.toString()}/download-url`;
                                        const response = await axios.get(url);
                                        let { url:exported_url } = response.data.data;
                                        let parsedUrl = exported_url.replace('file/custom-doc', 'file/doc-viewer') + '?type=custom_doc';
                                        window.open(parsedUrl, '_blank');
                                    }
                                } catch (err) {
                                    console.log(err);
                                }
                            }}

                        >
                            <Iconify icon={'mdi:eye'} />
                        </IconButton>
                    }


                    {
                        (selectedCategory?.name != 'Pending') && (
                            <IconButton color="primary" onClick={() => { onDownloadClick(doc) }}>
                                <Iconify icon={'akar-icons:download'} />
                            </IconButton>
                        )
                    }




                    
                </TableCell>
            )}

            {(onSelectDoc !== null) && (
                <TableCell align="right">
                    {/* <IconButton color="primary" 
                        onClick={() => { 
                            // @ts-ignore
                            onSelectDoc(doc);
                        }}
                    >
                        <Iconify icon={'material-symbols:check-small-rounded'} height={30} width={30} />
                    </IconButton> */}

                    <Button
                        variant="outlined"
                        startIcon={<Iconify icon={'material-symbols:done'} height={25} width={25} />}
                        onClick={() => onSelectDoc(doc)}
                    >
                        Select
                    </Button>
                </TableCell>
            )}
        </TableRow>
    );
}