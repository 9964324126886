import { useState, useCallback, ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
// @mui
import {
    Box, Table,
    TableContainer, TableRow, TableBody, TableCell, TableHead,
    Typography, IconButton,
} from '@mui/material';
import Scrollbar from '../../../components/Scrollbar';
// components
import Iconify from '../../../components/Iconify';
import { ConfirmationDialog } from '../../../components/dialogs';
import { CategoryRow, DocumentRow } from './DocumentTable';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//util
import { parseBizhavenError } from '../../../utils/parseError';
// types
import { DocumentType, DocumentSubcategoryType, DocumentCategoryType } from '../../../@types/user';
// config
import { DocumentCategoryEnum } from '../../../config';

// ----------------------------------------------------------------------

type Props = {
    filteredDocuments?: DocumentType[];
    category: DocumentCategoryType;
    subcategory: DocumentSubcategoryType | null;
    onSelectCategory: Function;
    onSelectSubcategory: Function;
    onDelete: Function;
    onSelectDoc?: Function | null;
    titleComponent?: ReactNode;
    hideBackButton?: boolean;
    retUrl?: string;
};

type ConfirmModalState = {
    isOpen: boolean;
    doc: DocumentType | null;
}

const initialConfirmModalState: ConfirmModalState = {
    isOpen: false,
    doc: null
}

// ----------------------------------------------------------------------

export default function DocumentsTable({
    category, subcategory, onSelectCategory, onSelectSubcategory, 
    onDelete, onSelectDoc,filteredDocuments,
    titleComponent = null, hideBackButton = false, retUrl = ''
}: Props) {
    const navigate = useNavigate();
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const [confirmModalState, setConfirmModalState] = useState(initialConfirmModalState);
    const [documents, setDocuments] = useState<DocumentType[]>([]);

    useEffect(() => {
        if(subcategory === null){
            setDocuments([...category.documents]);
        }else{
            setDocuments([...subcategory.documents]);
        }
    }, [category, subcategory]);

    const handleDownload = useCallback(async (item: DocumentType, type = 'download') => {
        try {
            let url = item.get_file_url;
            const response = await axios.get(url);

            if (isMountedRef.current) {
                let { url } = response.data.data;
                if (type === 'view') {
                    url = url.replace('admin/document', 'file/doc-viewer') + '?type=' + item.type_document;
                }
                window.open(url, '_blank');
            }
        } catch (err) {
            console.log(err);

            let msg = parseBizhavenError(err);
            enqueueSnackbar(msg, { variant: 'error' });
        }
    }, [isMountedRef, enqueueSnackbar]);

    const onDeleteCustomDoc = async (doc: DocumentType) => {
        try {
            //custom-doc or document
            let deleteType = doc.get_file_url.split('/');
            let url = `/app/admin/${deleteType[3]}/${doc.id}`;
            let formData = { _method: 'DELETE' };

            await axios.post(url, formData);

            if (isMountedRef.current) {
                let msg = 'Document deleted!';
                enqueueSnackbar(msg);
                onDelete(doc)
            }
        } catch (error) {
            console.log(error);

            if (isMountedRef.current) {
                let msg = parseBizhavenError(error);
                enqueueSnackbar(msg, { variant: 'error' });
            }
        }
    };

    const handleConfirmDeleteButton = (choice: boolean) => {
        if (choice) {
            onDeleteCustomDoc(confirmModalState.doc as DocumentType);
        }

        closeConfirmationModal();
    };

    // OPEN CONFIRMATION MODAL
    const openConfirmationModal = (_doc: DocumentType) => {
        setConfirmModalState({
            isOpen: true,
            doc: _doc
        });
    }

    // CLOSE CONFIRMATION MODAL
    const closeConfirmationModal = () => {
        setConfirmModalState(initialConfirmModalState);
    }

    const goToEditPage = (doc: DocumentType) => {
        if(retUrl){
            navigate(`/admin/custom-document/${doc.id}/edit?ret_url=${retUrl}&doc_type=${DocumentCategoryEnum.onboarding}`);
            return;
        }
        navigate(`/admin/custom-document/${doc.id}/edit`);
    }

    return (
        <>
            {subcategory !== null && (
                <Box>
                    {!hideBackButton && (
                        <IconButton
                            color="primary"
                            sx={{ height: 50, width: 50, display: 'inline-block' }}
                            onClick={() => onSelectCategory(category.id.toString())}
                        >
                            <Iconify icon={'akar-icons:arrow-back'} width={20} height={20} />
                        </IconButton>
                    )}

                    {titleComponent ? titleComponent : (
                        <Typography
                            variant="subtitle1"
                            sx={{ ml: 2, mb: 2, display: 'inline-block' }}
                        >
                            {subcategory.name}
                        </Typography>
                    )}
                </Box>
            )}

            <Scrollbar sx={{ flexGrow: 1 }}>
                <TableContainer sx={{ minWidth: 720 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                { category.name === 'Pending' && <TableCell>Employee Name</TableCell> }
                                { (category.name === 'HR' || category.name === 'Safety') && <TableCell>Size</TableCell> }
                                <TableCell>Date</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {!filteredDocuments && (
                                <>
                                    {(subcategory === null) && category.subcategories.map((row) => (
                                        <CategoryRow
                                            key={row.id}
                                            category={row}
                                            onCategoryClick={() => onSelectSubcategory(row)}
                                        />
                                    ))}
                                    
                                    {documents.map((row) => (
                                        <DocumentRow
                                            key={row.id}
                                            doc={row}
                                            selectedCategory={category}
                                            onDownloadClick={handleDownload}
                                            onEditClick={goToEditPage}
                                            opDeleteClick={openConfirmationModal}
                                            onSelectDoc={onSelectDoc}
                                        />
                                    ))}
                                </>
                            )}
                            
                            {filteredDocuments && filteredDocuments.map((row) => (
                                <DocumentRow
                                    key={row.id}
                                    doc={row}
                                    onDownloadClick={handleDownload}
                                    onEditClick={goToEditPage}
                                    opDeleteClick={openConfirmationModal}
                                    onSelectDoc={onSelectDoc}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
            {documents.length === 0 && (category && category.name.toLowerCase() === 'pending') && <>
                <p style={{
                    textAlign: 'center',
                    marginTop: '20px',
                    color: '#000',
                    fontSize: '16px',
                }}>
                    No pending documents to sign.
                </p>
            </>}

            <ConfirmationDialog
                title="Delete Document"
                message="Are you sure you want to delete this document?"
                open={confirmModalState.isOpen}
                onCloseConfirm={handleConfirmDeleteButton}
            />
        </>
    );
}
