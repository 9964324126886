// @mui
import { ToggleButtonGroup, ToggleButton, Typography } from '@mui/material';
// types
import { DocumentCategoryType } from '../../../@types/user';

// ----------------------------------------------------------------------

type Props = {
    curTab: string;
    categories: DocumentCategoryType[];
    onTabClick: Function;
};

// ----------------------------------------------------------------------

export default function DocumentCategoryTabs({ curTab, categories, onTabClick }: Props) {
    return (
        <>
            <ToggleButtonGroup
                color="primary"
                value={curTab.toString()}
                exclusive
                aria-label="text alignment"
            >
                {categories.map((item) => (
                    <ToggleButton
                        sx={{ ...MY_STYLE.btn_style }}
                        key={item.id.toString()}
                        value={item.id.toString()}
                        aria-label={item.name}
                        onClick={() => onTabClick(item.id)}
                    >
                        {item.name}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>

            {(curTab.toString() === '8') && (
                <Typography variant="subtitle2" sx={{ mb: 2 }}>
                    All employees can view documents in the "employees" section
                </Typography>
            )}
        </>
    );
}

const MY_STYLE = {
    btn_style: {
        minWidth: '58px',
    },
    btn_icon: {
      '@media (max-width: 899px)': {
        height: '22px',
        width: '22px',
      },
      '@media (min-width: 899px)': {
        marginRight: '10px',
        height: '20px',
        width: '20px',
      }
    }
  }
