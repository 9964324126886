import { Outlet } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Link, Button, AppBar, Toolbar, Container, Typography, Stack } from '@mui/material';
// hooks
import useOffSetTop from '../hooks/useOffSetTop';
// utils
import cssStyles from '../utils/cssStyles';
// config
import { HEADER } from '../config';
// components
import Logo from '../components/Logo';
// assets
import { LogoMd } from '../assets';

// ----------------------------------------------------------------------

export default function GetStartedLayout() {
  return (
    <Stack sx={{ minHeight: 1 }}>
      <GetStartedHeader />

      <Outlet />

      <Box sx={{ flexGrow: 1 }} />
        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default',
          }}
        >
          <Container>
            <Logo sx={{ mb: 1, mx: 'auto' }} />

            <Typography variant="caption" component="p">
              © All rights reserved
              <br /> 
              {/* Powered By&nbsp; */}
              <Link href="https://bizhaven.com/">Bizhaven</Link>
            </Typography>
          </Container>
        </Box>
    </Stack>
  );
}

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}));

function GetStartedHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);

  const theme = useTheme();

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            ...cssStyles(theme).bgBlur(),
            height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
          }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {/* <Logo />

          <Typography variant="h4" color="black" sx={{ ml: 1 }}>
            Bizhaven
          </Typography> */}
          <Box>
            <img
              style={{ height: '35px', margin: 'auto' }}
              src={LogoMd}
              alt="Logo"
              loading="lazy"
            />
          </Box>
          
          <Box sx={{ flexGrow: 1 }} />

          <Button
            variant="contained"
            target="_self"
            rel="noopener"
            href="https://bizhaven.com/"
          >
            Back To Main Site
          </Button>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
