import React, { lazy } from 'react';
import Loadable from "./loadable";
import RoleBasedGuard from "../guards/RoleBasedGuard";
import { PATH_AFTER_LOGIN, ROLE } from "../config";
import SuperDashboardLayout from "../layouts/super/dashboard";
import { Navigate } from "react-router-dom";

const SuperDashboardPage = Loadable(lazy(() => import('../pages/super/SuperDashboardPage')));
const SuperProfilePage = Loadable(lazy(() => import('../pages/super/SuperProfilePage')));

const SuperRoutes = [
    {
        path: 'super',
        element: (
            <RoleBasedGuard accessibleRoles={[ROLE.SUPER]}>
                <SuperDashboardLayout />
            </RoleBasedGuard>
        ),
        children: [
            { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
            { path: 'dashboard', element: <SuperDashboardPage /> },
            { path: 'profile', element: <SuperProfilePage /> },
        ],
    },
];

export default SuperRoutes;
