import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import { useCallback } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, Button, Alert, Typography, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField, RHFUploadSingleFile } from '../../../components/hook-form';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// util
import { parseBizhavenError } from '../../../utils/parseError';

// ----------------------------------------------------------------------

const getInitialValues = () => {
  const _announcement = {
    description: '',
    file: null,
    afterSubmit: ''
  };

  return _announcement;
};

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

type FormValuesProps = {
  description: string;
  file: File | any;
};

type Props = {
  handbookType: string;
  onCancel: VoidFunction;
};

export default function HandbookForm({ handbookType, onCancel }: Props) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const EventSchema = Yup.object().shape({
    description: Yup.string().max(5000).required('Description is required'),
    file: Yup.mixed().required('File is required'),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(),
  });

  const {
    reset,
    setValue,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'file',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const onSubmit = async (data: FormValuesProps) => {
    try {
      var formData = new FormData();
      formData.append("description", data.description);
      formData.append("file", data.file);

      let url = '';
      url = (handbookType === 'handbook') ? '/app/admin/dashboard/handbook/employee' : url;
      url = (handbookType === 'safety') ? '/app/admin/dashboard/handbook/safety' : url;

      await axios.post(url, formData);

      if (isMountedRef.current) {
        let msg = (handbookType === 'handbook') ? 'Handbook updated!' : 'Safety Manual updated!';

        enqueueSnackbar(msg);
        onCancel();
        reset();
      }
    } catch (error) {
      console.log(error);

      if (isMountedRef.current) {
        let message = parseBizhavenError(error);
        error.message = message;
        
        setError('afterSubmit', error);
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <RHFTextField name="description" label="Description" multiline rows={4} />

        <div>
          <LabelStyle>File (pdf)</LabelStyle>
          <RHFUploadSingleFile
            name="file"
            accept=".pdf"
            maxSize={5242880}
            onDrop={handleDrop}
          />
        </div>

        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      </Stack>

      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting}
          loadingIndicator="Loading..."
        >
          Add
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
