import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useAuth from '../../../hooks/useAuth';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// util
import { parseBizhavenError } from '../../../utils/parseError';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  afterSubmit?: string;
};

type Props = {
    onSuccess: Function;
};

// ----------------------------------------------------------------------

export default function Auth2FAForm({ onSuccess }: Props) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useAuth();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: ''
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    const loginResp = await submitLogin(data);

    if(!loginResp) {
      send2FACode(data);
    }
  };

  const submitLogin = async (data: FormValuesProps) => {
    try {
      await login(data.email, data.password);
      return true;
    } catch (error) {
      if (isMountedRef.current) {
        // let message = parseBizhavenError(error);
        // let errorOption = { message: message };
        return false;
      }
    }
  };

  const send2FACode = async (data: FormValuesProps) => {
    try {
      const url = '/app/auth/2fa/send-code';
      const response = await axios.post(url, data);

      if (isMountedRef.current) {
        const { msg } = response.data.data;
        enqueueSnackbar(msg, { variant: 'success', autoHideDuration: 10000  });

        const registerValues = { email: data.email, password: data.password };
        onSuccess(registerValues);
      }
  } catch (error) {
    reset ({
      email: data.email,
      password: ''
    });

    if (isMountedRef.current) {
      let message = parseBizhavenError(error);
      let errorOption = { message: message };

      setError('afterSubmit', errorOption);
    }
  }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" type="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
    </FormProvider>
  );
}
