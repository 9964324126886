import { format } from 'date-fns';
// @mui
import {
    TableRow, TableCell, Typography, Button,
} from '@mui/material';
// components
import Iconify from '../../../../components/Iconify';
// types
import { DocumentSubcategoryType } from '../../../../@types/user';

// ----------------------------------------------------------------------

type Props = {
    category: DocumentSubcategoryType;
    onCategoryClick: Function;
};

// ----------------------------------------------------------------------

export default function CategoryRow({ category, onCategoryClick }: Props) {
    return (
        <TableRow
            key={category.id}
            sx={{ 'td': { borderBottom: 'solid 1px #f4f6f8' } }}
        >
            <TableCell>
                <Typography 
                    variant="body2" 
                    onClick={() => onCategoryClick(category)}
                    sx={{ cursor: 'pointer' }}
                >
                    {category.name}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="body2">{category.documents.length}</Typography>
            </TableCell>

            <TableCell>
                {format(new Date(category.created_at), 'MMM dd, yyyy')}
            </TableCell>

            <TableCell align="right">
                <Button
                    variant="outlined"
                    startIcon={<Iconify icon={'akar-icons:folder'} />}
                    onClick={() => onCategoryClick(category)}
                >
                    View
                </Button>
            </TableCell>
        </TableRow>
    );
}