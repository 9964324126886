// @mui
import {
    Box, Card, CardHeader
} from '@mui/material';
// sections
import {
    UpcomingCoursesSection,
    UpcomingSurveysSection,
    UpcomingReviewsSection
} from './upcoming-card';

// ----------------------------------------------------------------------

export default function AdminUpcomingCourses() {
    return (
        <Box sx={{ height: '100%' }}>
            <Card sx={{ mb: 3 }}>
                <CardHeader title="Training" subheader="" sx={{ textAlign: 'center' }} />
                <UpcomingCoursesSection />
            </Card>
            <Card sx={{ mb: 3 }}>
                <CardHeader title="Surveys" subheader="" sx={{ textAlign: 'center' }} />
                <UpcomingSurveysSection />
            </Card>
            <Card>
                <CardHeader title="Reviews" subheader="" sx={{ textAlign: 'center' }} />
                <UpcomingReviewsSection />
            </Card>
        </Box>
    )
}
