import { useState, useCallback, useEffect } from 'react';
import axios from 'src/utils/axios';
import { useNavigate } from 'react-router-dom';
// @mui
import {
    Box, Card, Stack, Grid,
    Typography, CardHeader
} from '@mui/material';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { SkeletonLineItem } from '../../../components/skeleton';
// sections
import TutorialTaskMenu from './TutorialTaskMenu';
// utils
import moment from 'moment';

// ----------------------------------------------------------------------

type TutorialTask = {
    id: string;
    key: string;
    iconify: string;
    title: string;
    description: string;
    completed_at: string;
    app_page_path: string;
};
const initialTasks: Array<TutorialTask> = [];

// ----------------------------------------------------------------------

export default function TutorialTasks() {
    const isMountedRef = useIsMountedRef();
    const navigate = useNavigate();
    const [tasks, setTasks] = useState(initialTasks);
    const [isLoading, setIsLoading] = useState(false);

    const getTasks = useCallback(async () => {
        try {
            setIsLoading(true);

            const response = await axios.get('/app/admin/dashboard/tutorial/tasks', {
                params: {},
            });

            if (isMountedRef.current) {
                let { data } = response.data;
                
                setTasks(data);
                setIsLoading(false);
            }
        } catch (err) {
            setIsLoading(false);
            console.log(err);
        }
    }, [isMountedRef]);

    useEffect(() => {
        getTasks();
    }, [getTasks]);

    const markTaskComplete = async (task: TutorialTask) => {
        let url = '/app/admin/dashboard/tutorial/task/complete';
        let data = {
            key: task.key
        };

        try {
            await axios.post(url, data);

            if (isMountedRef.current) {
                updateTaskCompleteAt(task);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const scrollToBottom = () => {
        window.scroll({
            top: document.body.offsetHeight,
            left: 0, 
            behavior: 'smooth',
        });
    }

    const onItemClick = (task: TutorialTask) => {
        if(task.app_page_path === '#benefits'){
            scrollToBottom();
        }else{
            navigate(task.app_page_path, { replace: true });
        }
    }

    const onMarkComplete = (task: TutorialTask) => {
        markTaskComplete(task);
    }

    const updateTaskCompleteAt = (task: TutorialTask) => {
        // set task complete
        let oldTaskIndex = tasks.findIndex((item) => item.id.toString() === task.id.toString());

        if(oldTaskIndex !== -1){
            let oldTask = tasks[oldTaskIndex];
            oldTask.completed_at = moment().format('Y-m-d');

            setTasks(
                tasks.map(el => (el.id === oldTask.id ? oldTask : el))
            );
        }
    };

    return (
        <Card>
            <CardHeader
                title="Let's Get Started!"
                subheader="Complete the checklist below to set up your account."
            />
            {isLoading ? (
                <SkeletonItems />
            ) : (
                <Grid container p={4} spacing={3}>
                    {tasks.map((item) => (
                        <Grid key={item.id} item xs={12} md={12} lg={6}>
                            <Stack direction="row" alignItems="center" key={item.id}>
                                <Box>
                                    <Iconify color={'#56397b'} icon={item.iconify} width={46} height={46} />
                                </Box>

                                <Box sx={{ flexGrow: 1, ml: 2, minWidth: 100 }}>
                                    <Typography variant="subtitle2" 
                                        sx={{ 
                                            mb: 0.5,
                                            color: '#212b36',
                                            textDecoration: 'none',
                                            '&:hover': {
                                              cursor: 'pointer'
                                            }
                                        }} 
                                        noWrap
                                        onClick={() => onItemClick(item)}
                                    >
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                                        {item.description}
                                    </Typography>
                                </Box>

                                { !!item.completed_at && (
                                    <Iconify style={{ color: '#54D62C'  }} icon={'bi:check-lg'} width={36} height={36} />
                                )}

                                { !(!!item.completed_at) && (
                                    <TutorialTaskMenu
                                        task={item}
                                        onGetStartedClick={onItemClick}
                                        onMarkCompleteClick={onMarkComplete}
                                    />
                                )}
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Card>
    );
}

function SkeletonItems() {
    const numItems: number[] = [1,2,3,4,5];

    return (
        <Grid container p={4} spacing={3}>
            {numItems.map((item) => (
                <Grid key={item} item xs={12} md={12} lg={6}>
                    <SkeletonLineItem/>
                </Grid>
            ))}
        </Grid>
    );
  }