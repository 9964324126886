import { useState } from 'react';
// @mui
import { IconButton, MenuItem } from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';

// ----------------------------------------------------------------------

type TutorialTask = {
    id: string;
    key: string;
    iconify: string;
    title: string;
    description: string;
    completed_at: string;
    app_page_path: string;
};

type Props = {
    task: TutorialTask;
    onGetStartedClick: Function;
    onMarkCompleteClick: Function;
};

// ----------------------------------------------------------------------

export default function TutorialTaskMenu({ task, onGetStartedClick, onMarkCompleteClick }: Props) {
    const [open, setOpen] = useState<HTMLElement | null>(null);

    const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        setOpen(e.currentTarget);
    };

    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault()
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();

        setOpen(null);
    };

    const handleGetStarted = (e: React.MouseEvent<HTMLElement>) => {
        handleClose(e);
        onGetStartedClick(task);
    }

    const handleMarkComplete = (e: React.MouseEvent<HTMLElement>) => {
        handleClose(e);
        onMarkCompleteClick(task);
    }

    const ICON = {
        mr: 2,
        width: 20,
        height: 20,
    };

    return (
        <>
            <IconButton size="large" onClick={handleOpen} sx={{ p: 0 }}>
                <Iconify icon={'akar-icons:question'} width={30} height={30} style={{ color: '#56397b' }} />
            </IconButton>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                arrow="right-top"
                sx={{
                    mt: -0.5,
                    width: 180,
                    '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
                }}
            >
                <MenuItem onClick={(e) => { handleGetStarted(e) }}>
                    <Iconify icon={'bi:hand-index-thumb'} sx={{ ...ICON, color: '#56397b' }} />
                    Get Started
                </MenuItem>

                <MenuItem onClick={(e) => { handleMarkComplete(e) }} >
                    <Iconify icon={'bi:check-lg'} sx={{ ...ICON, color: '#54D62C' }} />
                    Mark Complete
                </MenuItem>
            </MenuPopover>
        </>
    );
}