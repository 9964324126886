import React, { lazy } from 'react';
import GuestGuard from '../guards/GuestGuard';
import Loadable from "./loadable";

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const ResetPasswordConfirm = Loadable(lazy(() => import('../pages/auth/ResetPasswordConfirm')));
// const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

const AuthRoutes = [
    {
        path: 'auth',
        children: [
            {
                path: 'login',
                element: (
                    <GuestGuard>
                        <Login />
                    </GuestGuard>
                ),
            },
            {
                path: 'register',
                element: (
                    <GuestGuard>
                        <Register />
                    </GuestGuard>
                ),
            },
            {
                path: 'register',
                element: (
                    <GuestGuard>
                        <Register />
                    </GuestGuard>
                ),
            },
            { path: 'login-unprotected', element: <Login /> },
            { path: 'register-unprotected', element: <Register /> },
            { path: 'reset-password', element: <ResetPassword /> },
            { path: 'verify', element: <ResetPasswordConfirm /> },
        ],
    },
];

export default AuthRoutes;
