// @mui
import {
    Stack, Button, Typography, Grid,
    DialogProps, DialogTitle, DialogContent, DialogActions,
} from '@mui/material';
// components
import { DialogAnimate } from '../animate';

// ----------------------------------------------------------------------

interface Props extends DialogProps {
    title: string;
    message: string;
    open: boolean;
    onCloseConfirm: (confirmed: boolean) => void;
    id?: string;
}

// ----------------------------------------------------------------------

export default function ConfirmationDialog({ title, open, onCloseConfirm, id = 'confirm-dialog', message = ''}: Props) {
    return (
        <DialogAnimate fullWidth open={open} onClose={() => onCloseConfirm(false)} id={id}>
            <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>{title}</DialogTitle>

            <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
                <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
                    <Typography>{message}</Typography>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Button variant="outlined" color="inherit" onClick={() => onCloseConfirm(false)}>
                            No
                        </Button>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                        <Button variant="contained" onClick={() => onCloseConfirm(true)} >
                            Yes
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </DialogAnimate>
    );
}
