import { useState, useCallback, useEffect } from 'react';
import axios from 'src/utils/axios';
// @mui
import {
    Box, CircularProgress,Grid, Button,
    DialogTitle, Dialog, DialogProps, DialogContent, DialogActions,
} from '@mui/material';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
// sections
import { DocumentCategoryTabs, DocumentsTable } from '../document';
// types
import { DocumentType, DocumentSubcategoryType, DocumentCategoryType } from '../../../@types/user';
// config
import { DocumentParentTypeEnum } from '../../../config';

// ----------------------------------------------------------------------

const initialCategory: DocumentCategoryType = {
    id: '',
    name: '',
    documents: [],
    subcategories: [],
    status: '',
    created_by: '',
    created_at: '',
    updated_at: '',
}

const initialCategories: DocumentCategoryType[] = [];

interface Props extends DialogProps {
    open: boolean;
    onCloseConfirm: (docInfo: any) => void;
    onSelectDoc: (doc: DocumentType) => void;
}

// ----------------------------------------------------------------------

export default function SelectTemplateDialog({ open, onCloseConfirm, onSelectDoc }: Props) {
    const isMountedRef = useIsMountedRef();
    const [isLoading, setIsLoading] = useState(true);
    const [curTab, setCurTab] = useState('');
    const [categories, setCategories] = useState(initialCategories);
    const [curCategory, setCurCategory] = useState<DocumentCategoryType>(initialCategory);
    const [curSubcategory, setCurSubcategory] = useState<DocumentSubcategoryType | null>(null);

    const getDocuments = useCallback(async () => {
        try {
            const url = `/app/admin/document/all/categorized`;

            setIsLoading(true);

            const response = await axios.get(url);

            setIsLoading(false);

            if (isMountedRef.current) {
                let { data } = response.data;

                setCategories(data);

                if (data.length > 0) {
                    setCurCategory(data[0]);
                    setCurTab(data[0].id.toString());
                }
            }
        } catch (err) {
            setIsLoading(false);
            console.log(err);
        }
    }, [isMountedRef]);

    useEffect(() => {
        getDocuments();
    }, [getDocuments]);

    useEffect(() => {
        if (categories.length > 0) {
            if (curCategory.id !== '') {
                // @ts-ignore
                const newCurCategory = categories.find(item => item.id === curCategory.id);

                if (newCurCategory === undefined) {
                    setCurCategory(categories[0]);
                    setCurTab(categories[0].id.toString());
                } else {
                    setCurCategory(newCurCategory);
                }
            }
        }
    }, [categories, setCurCategory, setCurTab, curCategory]);

    const onDeleteDocument = useCallback((doc: DocumentType) => {
        switch (doc.type) {
            case DocumentParentTypeEnum.category:
                // find the category index
                const categoryIndex = categories.findIndex(item => item.id === doc.type_id);

                if (categoryIndex > -1) {
                    const newCategories = [...categories];
                    const newCategory = { ...categories[categoryIndex] };
                    newCategory.documents = newCategory.documents.filter(item => item.id !== doc.id);
                    newCategories[categoryIndex] = newCategory;
                    setCategories(newCategories);
                }
                break;
            case DocumentParentTypeEnum.subcategory:
                // find the subcategory
                let subcatIndex = -1;

                categories.forEach(category => {
                    if (subcatIndex === -1) {
                        subcatIndex = category.subcategories.findIndex(item => item.id === doc.type_id);
                    }
                });

                if (subcatIndex === -1) {
                    const newCategories = [...categories];
                    const newCategory = { ...categories[subcatIndex] };
                    const newSubcategories = [...newCategory.subcategories];
                    const newSubcategory = { ...newSubcategories[subcatIndex] };
                    newSubcategory.documents = newSubcategory.documents.filter(item => item.id !== doc.id);
                    newSubcategories[subcatIndex] = newSubcategory;
                    newCategory.subcategories = newSubcategories;
                    newCategories[subcatIndex] = newCategory;
                    setCategories(newCategories);
                }
                break;
            default:
                console.log('Unknown document parent type: ', doc.type);
                break;
        }
    }, [categories, setCategories]);

    const onTabClick = (tabId: string) => {
        setCurTab(tabId);

        const category = categories.find(item => item.id.toString() === tabId.toString());

        if (category) {
            onSelectCategory(category);
        }
    }

    /**************************
     * DOCUMENTS SEARCH MODEL *
     **************************/

    const onSelectCategory = (category: DocumentCategoryType) => {
        setCurCategory(category);
        setCurSubcategory(null);
    }

    const onSelectSubcategory = (subcategory: DocumentSubcategoryType) => {
        setCurSubcategory(subcategory);
    }

    return (
        <Dialog fullWidth maxWidth="xl" open={open} onClose={() => onCloseConfirm('')}>
            <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>Templates</DialogTitle>

            <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
                {isLoading && (
                    <Box sx={{ mt: 4, height: 70, display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                    </Box>
                )}

                {(!isLoading &&
                    <Box sx={{ ml: 1, mb: 2 }}>
                        <DocumentCategoryTabs
                            curTab={curTab}
                            categories={categories}
                            onTabClick={onTabClick}
                        />
                    </Box>
                )}

                {!isLoading && (
                    <DocumentsTable
                        category={curCategory}
                        subcategory={curSubcategory}
                        onSelectCategory={onTabClick}
                        onSelectSubcategory={onSelectSubcategory}
                        onDelete={onDeleteDocument}
                        onSelectDoc={onSelectDoc}
                    />
                )}
            </DialogContent>

            <DialogActions>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Button variant="outlined" color="inherit" onClick={() => onCloseConfirm('')}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
