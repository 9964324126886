import { useNavigate } from 'react-router-dom';
// mui
import { IconButton } from '@mui/material';
// components
import Iconify from './Iconify';

export default function BackButton() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

    return (
        <IconButton size="small" color="primary" onClick={goBack} sx={{ marginLeft: '-17px' }}>
            <Iconify icon={'material-symbols:chevron-left-rounded'} width={40} height={40} />
        </IconButton>
    );
}
