import { useEffect, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// @mui
import {
  InputAdornment, Box, Typography, Grid,
  FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
import InputStyle from '../../../components/InputStyle';

// ----------------------------------------------------------------------

const DUE_DATE_OPTIONS = [
  {
    id: 'next_30_days',
    label: 'Due in 30 Days'
  },
  {
    id: 'next_90_days',
    label: 'Due in 90 Days'
  },
  {
    id: 'next_180_days',
    label: 'Due in 6 Months'
  },
  {
    id: 'next_365_days',
    label: 'Due in a Year'
  }
]

const initNewAssignment = { query: '', training_id: '', due_at: '' };

// ----------------------------------------------------------------------

type FilterType = {
  query: string;
  training_id: string;
  due_at: string;
}

type Props = {
  formOptions: any;
  onFilterChange: (value: FilterType) => void;
};

export default function TrackCourseToolbar({ formOptions, onFilterChange }: Props) {
  const [filter, setFilter] = useState(initNewAssignment);
  const [searchParams] = useSearchParams();

  const init = useCallback(() => {
    if (!!searchParams.get('due_at')) {
      let tmpVal: string | null = searchParams.get('due_at');

      if (tmpVal != null) {
        let dueAtVal: string = tmpVal;

        let newValues = { ...filter, due_at: dueAtVal };

        setFilter(newValues);
        onFilterChange(newValues);
      }
    }
  }, [filter, onFilterChange, searchParams]);

  useEffect(() => {
    init();
  }, [init]);

  const onQueryChange = (event: any) => {
    const { target: { value } } = event;

    let newValues = { ...filter, query: value };
    setFilter(newValues);
    onFilterChange(newValues);
  };

  const handleTrainingSelect = (event: any) => {
    const { target: { value } } = event;

    let newValues = { ...filter, training_id: value };
    setFilter(newValues);
    onFilterChange(newValues);
  };

  const handleDueAtSelect = (event: any) => {
    const { target: { value } } = event;

    let newValues = { ...filter, due_at: value };
    setFilter(newValues);
    onFilterChange(newValues);
  };

  return (
    <Box>
      <Typography variant="subtitle1" mb={1}>
        Filter/Search
      </Typography>

      <Grid container spacing={2}>

        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <InputStyle
            fullWidth
            value={filter.query}
            // onChange={(event) => onQueryChange(event.target.value)}
            onChange={onQueryChange}
            placeholder="Search user..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
          <FormControl fullWidth>
            <InputLabel>Training</InputLabel>
            <Select
              value={filter.training_id}
              label="Training"
              onChange={handleTrainingSelect}
            >
              <MenuItem key={'empty'} value={''}> - </MenuItem>

              {formOptions.trainings.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>

          <FormControl fullWidth>
            <InputLabel>Due In</InputLabel>
            <Select
              value={filter.due_at}
              label="Training"
              onChange={handleDueAtSelect}
            >
              <MenuItem key={'empty'} value={''}> - </MenuItem>

              {DUE_DATE_OPTIONS.map((option: any) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <FormControl fullWidth>
            <TextField
              sx={{ maxWidth: '250px' }}
              variant="outlined"
              type="date"
              name="due_at"
              label="Deadline"
              InputLabelProps={{ shrink: true }}
              value={newAssignment.due_at}
              onChange={handleDueAtSelect} />
          </FormControl> */}
        </Grid>
      </Grid>
    </Box>
  );
}
