import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import { useState, useCallback, useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
    Box, Stack, Button, Alert, DialogActions, Tooltip, IconButton,
    FormControl, InputLabel, Select, OutlinedInput, MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
// hooks
import { FormProvider, RHFTextField, RHFSelect } from '../../../components/hook-form';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// util
import { parseBizhavenError } from '../../../utils/parseError';
// @types
import { CompanyEventType } from 'src/@types/user';

// ----------------------------------------------------------------------

const getInitialValues = () => {
    let _event = {
        title: '',
        type: '',
        event_date: '',
        assignment: '',
        department_id: '',
        user_ids: [],
        afterSubmit: ''
    };

    return _event;
};

// ----------------------------------------------------------------------

const initialFormOptions = {
    assignment_options: [],
    departments: [],
    users: []
};

const initialPersonNames: number[] = [];
const defaultEvent: CompanyEventType = {
  id: '',
  company_id: '',
  created_by: '',
  end_time: '',
  event_date: '',
  event_time: '',
  status: '',
  title: '',
  type: '',
};

type FormValuesProps = {
    title: string;
    type: string;
    event_date: string;
    assignment: string;
    department_id: string;
    user_ids: string[],
};

type Props = {
    curEvent: CompanyEventType | null;
    onCancel: Function;
    onDelete: Function;
};

export default function CompanyEventForm({ curEvent, onCancel, onDelete }: Props) {
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    // user select
    const [formOptions, setFormOptions] = useState(initialFormOptions);
    const [personName, setPersonName] = useState(initialPersonNames);
    const [companyEvent, setCompanyEvent] = useState(defaultEvent);

    const EventSchema = Yup.object().shape({
        title: Yup.string().max(5000).required('Title is required'),
        type: Yup.string().max(5000).required('Type is required'),
        event_date: Yup.string().max(5000).required('Date is required'),
    });

    const methods = useForm({
        resolver: yupResolver(EventSchema),
        defaultValues: getInitialValues()
    });

    const {
        reset,
        watch,
        setValue,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;

    const handleUserMultiSelectChange = (event: any) => {
        const { target: { value } } = event;
        setPersonName(typeof value === 'string' ? value.split(',') : value,);
        setValue('user_ids', value);
    };

    const getEvent = useCallback(async (eventId: string) => {
        try {
            let url = `/app/admin/company-event/${eventId}`;

            const response = await axios.get(url, {
                params: {},
            });

            if (isMountedRef.current) {
                let { data } = response.data;

                let { event } = data;
                let userIds = data.users.map((item: any) => item.id);
                
                setCompanyEvent(data.event);

                setValue('title', event.title);
                setValue('type', event.type);
                setValue('event_date', event.event_date);

                setPersonName(userIds);
                setValue('user_ids', userIds);
            }
        } catch (err) {
            console.log(err);
        }
    }, [isMountedRef, setValue]);

    const getFormOptions = useCallback(async () => {
        try {
            const response = await axios.get('/app/admin/company-event/form-options', {
                params: {},
            });

            if (isMountedRef.current) {
                let { data } = response.data;

                setFormOptions(data);

                if (data.departments.length > 0) {
                    setValue('department_id', data.departments[0].id);
                }
                if(data.assignment_options.length > 0){
                    setValue('assignment', data.assignment_options[0].key);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }, [isMountedRef, setValue]);

    useEffect(() => {
        if(curEvent != null){
            getEvent(curEvent.id);
        }

        getFormOptions();
    }, [curEvent, getEvent, getFormOptions]);

    const onSubmit = async (data: FormValuesProps) => {
        try {
            let formData: any = { ...data };

            let url = '/app/admin/company-event';

            // switch to update endpoint
            if (!!companyEvent.id) {
                url += '/' + companyEvent.id
                formData._method = 'PUT';
            }

            const response = await axios.post(url, formData);

            if (isMountedRef.current) {
                let msg = 'Event updated!';

                enqueueSnackbar(msg);
                onCancel(response.data.data);
                reset();
            }
        } catch (error) {
            console.log(error);

            if (isMountedRef.current) {
                let message = parseBizhavenError(error);
                error.message = message;

                setError('afterSubmit', error);
            }
        }
    };

    const handleOnDelete = async () => {
        try {
            if(companyEvent == null){
                return;
            }

            let url = '/app/admin/company-event/' + companyEvent.id;
            let data = {
                '_method': 'DELETE'
            };

            await axios.post(url, data);

            if (isMountedRef.current) {
                let msg = 'Event deleted!';

                enqueueSnackbar(msg);
                onCancel();
                reset();
                onDelete(companyEvent);
            }
        } catch (error) {
            console.log(error);

            if (isMountedRef.current) {
                let message = parseBizhavenError(error);
                error.message = message;

                setError('afterSubmit', error);
            }
        }
    }

    const assignment = watch('assignment');

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={3} sx={{ p: 3 }}>
                <RHFTextField name="title" label="Title" />
                <RHFTextField name="type" label="Type" placeholder='Ex: "Birthday"' />
                <RHFTextField type="date" name="event_date" label="Event Date" InputLabelProps={{ shrink: true }} />

                {(!(!!companyEvent?.id)) && (
                    <RHFSelect InputLabelProps={{ shrink: true }} name="assignment" label="Assignment" placeholder="" >
                        {formOptions.assignment_options.map((option: any) => (
                            <option key={option.key} value={option.key}>
                                {option.label}
                            </option>
                        ))}
                    </RHFSelect>
                )}

                {(assignment === 'department' && !(!!companyEvent?.id)) && (
                    <RHFSelect InputLabelProps={{ shrink: true }} name="department_id" label="Department" placeholder="" >
                        {formOptions.departments.map((option: any) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </RHFSelect>
                )}

                {(assignment === 'user' || companyEvent?.id) && (
                    <div>
                        <FormControl sx={{ width: '100%' }}>
                            <InputLabel id="user-ids-label">Users</InputLabel>
                            <Select
                                labelId="user-ids-label"
                                id="user-ids"
                                value={personName}
                                multiple
                                onChange={handleUserMultiSelectChange}
                                input={<OutlinedInput label="Users" />}
                                MenuProps={MenuProps}
                            >
                                {formOptions.users.map((item: any) => (
                                    <MenuItem
                                        key={item.id}
                                        value={item.id}
                                    >
                                        {item.first_name + ' ' + item.last_name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                )}

                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
            </Stack>

            <DialogActions>
                {companyEvent?.id && (
                    <Tooltip title="Delete Event">
                        <IconButton onClick={handleOnDelete} color="error">
                            <Iconify icon="eva:trash-2-outline" width={20} height={20} />
                        </IconButton>
                    </Tooltip>
                )}
                <Box sx={{ flexGrow: 1 }} />

                <Button variant="outlined" color="inherit" onClick={() => onCancel()}>
                    Cancel
                </Button>

                <LoadingButton
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    loadingIndicator="Loading..."
                >
                    Update
                </LoadingButton>
            </DialogActions>
        </FormProvider>
    );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
