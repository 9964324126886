const getFormattedDateForEvent = (date: any) => {
    const inputDate = new Date(date);
    const utcDate = new Date(inputDate.getTime() + inputDate.getTimezoneOffset() * 60000);
    const day = String(utcDate.getUTCDate()).padStart(2, '0');
    const month = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
    const year = utcDate.getUTCFullYear();
    return `${month}/${day}/${year}`;
}


export {
    getFormattedDateForEvent
}