import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import React, { useState, useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
    Box, Stack, Typography,
    Alert, DialogTitle,
    TableContainer, Table, TableBody, TableRow, TableCell, TableHead, IconButton
} from '@mui/material';
// components
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import Iconify from '../../../components/Iconify';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// util
import { parseBizhavenError } from '../../../utils/parseError';
import { dateIsPassed, fDateShorter } from '../../../utils/formatTime';
// @types
import { TrainingCourseType } from '../../../@types/user';
import { ConfirmationDialog } from "../../../components/dialogs";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { getFormattedDateForEvent } from "../../../utils/dateUtil";

// ----------------------------------------------------------------------

type AssignedUserType = {
    id: string;
    email: string;
    first_name: string;
    image: string;
    last_name: string;
    full_name: string;
    profile_image_url: string;
    total_modules_count: number;
    completed_modules_count: number;
    due_at: string;
    completed_at: string;
    expires_at: string;
    certificate_url: string;
}

const initAssignedUsers: AssignedUserType[] = [];

const defaultValues = {
    user_ids: [''],
    afterSubmit: ''
};

type ConfirmModalState = {
    isOpen: boolean;
    dueDate: string;
    employee: AssignedUserType | null;
    onYes: (data: any, dueAt: any) => void;
    onNo: (data: any, dueAt: any) => void;
}

const initialConfirmModalState: ConfirmModalState = {
    isOpen: false,
    dueDate: '',
    employee: null,
    onYes: (data: any, dueAt: any) => {},
    onNo: (data: any, dueAt: any) => {}
}

type Props = {
    onUserClick: Function;
    course: TrainingCourseType;
    users: AssignedUserType[];
};

// ----------------------------------------------------------------------

export default function AssignCourseTable({ onUserClick, course, users }: Props) {
    const [assignedUsers, setAssignedUsers] = useState(initAssignedUsers);
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const [confirmModalState, setConfirmModalState] = useState(initialConfirmModalState);

    useEffect(() => {
        setAssignedUsers(users);

        const getDeadlines = users.map ( (item: AssignedUserType) => {
            console.log("ITEM: ", item)
            return {
                user_id: item.id,
                date: item.due_at ? dayjs(item.due_at) : null
            }
        }).reduce((acc: any, item: any) => {
            acc[`${item.user_id}`] = item.date;
            return acc;
        }, {});

        setDeadlines(getDeadlines);

    }, [users]);

    const EventSchema = Yup.object().shape({
        //
    });

    const methods = useForm({
        resolver: yupResolver(EventSchema),
        defaultValues
    });

    const {
        formState: { errors },
    } = methods;

    const onSubmit = async (userId: string, dueAt: string) => {
        console.log("DUE AT: ", dueAt)
        try {
            let url = `/app/shared/training/${course.id}/assign-user`;
            
            let formData = new FormData();
            formData.append('user_id', userId);
            formData.append('due_at', dueAt);

            const response = await axios.post(url, formData);

            if (isMountedRef.current) {
                let msg = 'Assignment updated!';

                let { data } = response.data;

                enqueueSnackbar(msg);
                updateUser(data);
            }
        } catch (error) {
            console.log(error);

            if (isMountedRef.current) {
                let msg = parseBizhavenError(error);
                
                enqueueSnackbar(msg, {
                    variant: 'error'
                });
            }
        }
    };

    const updateUser = (user: any) => {
        setAssignedUsers(
            assignedUsers.map(el => (el.id === user.id ? user : el))
        );
    }

    const getExpiresColor = (dateStr: string) => {
        if(dateIsPassed(dateStr)){
            return '#FF4842';
        }else{
            return '#212b36';
        }
    }

    const handleConfirmButton = (confirmation: boolean) => {
        if (!confirmation) {
            confirmModalState.onNo(confirmModalState.employee?.id, confirmModalState.dueDate)
            return;
        }

        confirmModalState.onYes(confirmModalState.employee?.id, confirmModalState.dueDate);
    }

    const [deadlines, setDeadlines] = useState<any>({});

    return (
        <>
            <DialogTitle sx={{ mb: 1 }}>Assign</DialogTitle>

            <FormProvider methods={methods}>
                <Stack>
                    <TableContainer sx={{ minWidth: 720 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Due</TableCell>
                                    <TableCell>Assigned</TableCell>
                                    <TableCell>Complete</TableCell>
                                    <TableCell>Expires</TableCell>
                                    <TableCell>Cert.</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {assignedUsers.map((item, index) => (
                                    <TableRow key={`${item.first_name} ${item.last_name} ${item.id}`}>
                                        <TableCell sx={{ maxWidth: '170px' }}>
                                            <Box 
                                                onClick={() => (onUserClick(item))}
                                                sx={{
                                                    p: 2,
                                                    borderRadius: 1,
                                                    '&:hover': {
                                                      cursor: 'pointer',
                                                      background: '#eff0f1'
                                                    }
                                                  }}
                                            >
                                                <Iconify icon={'clarity:pencil-solid'} color="#56397b" width={16} height={16} /> {item.first_name} {item.last_name}
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <DatePicker
                                                className={`custom-date-picker-${item.first_name}_${item.last_name}`}
                                                format={'MM/DD/YYYY'}
                                                allowClear={false}
                                                value={deadlines[item.id]}
                                                onChange={(date, dateString) => {
                                                    const utcDateString = date.toDate().toUTCString();
                                                    const newDate = dayjs(utcDateString);
                                                    setDeadlines({
                                                        ...deadlines,
                                                        [item.id]: newDate
                                                    });
                                                    const formattedDate = getFormattedDateForEvent(date);
                                                    setConfirmModalState({
                                                        isOpen: true,
                                                        dueDate: formattedDate,
                                                        employee: item,
                                                        onYes: (data: any, dueAt: any) => {
                                                            onSubmit(item.id, newDate.format('YYYY-MM-DD'));
                                                            setConfirmModalState(initialConfirmModalState);
                                                        },
                                                        onNo: (data: any, dueAt: any) => {
                                                            setDeadlines({
                                                                ...deadlines,
                                                                [item.id]: item.due_at ? dayjs(item.due_at) : null
                                                            });
                                                            setConfirmModalState(initialConfirmModalState)
                                                        }
                                                    })
                                                }}
                                            />

                                            {/*<RHFTextField*/}
                                            {/*    sx={{ maxWidth: '250px' }}*/}
                                            {/*    type="date"*/}
                                            {/*    name="due_at"*/}
                                            {/*    label="Deadline"*/}
                                            {/*    defaultValue={item.due_at}*/}
                                            {/*    onChange={(e) => {openConfirmationModal(item, e)}}*/}
                                            {/*    InputLabelProps={{ shrink: true }}*/}
                                            {/*    value={item.due_at} */}
                                            {/*/>*/}
                                        </TableCell>

                                        <TableCell sx={{ maxWidth: '1px' }}>
                                            {!(!!item.due_at) && (
                                                <Iconify icon={'akar-icons:minus'} width={25} height={25} />
                                            )}
                                            {!!item.due_at && (
                                                <Iconify icon={'akar-icons:check'} width={25} height={25} color="#54D62C" />
                                            )}
                                        </TableCell>

                                        <TableCell sx={{ maxWidth: '1px' }}>
                                            {!(!!item.completed_at) && (
                                                <Iconify icon={'akar-icons:minus'} width={25} height={25} />
                                            )}
                                            {!!item.completed_at && (
                                                <Typography>{fDateShorter(item.completed_at)}</Typography>
                                            )}
                                        </TableCell>

                                        <TableCell sx={{ maxWidth: '1px' }}>
                                            {!(!!item.expires_at) && (
                                                <Iconify icon={'akar-icons:minus'} width={25} height={25} />
                                            )}
                                            {!!item.expires_at && (
                                                <Typography color={getExpiresColor(item.expires_at)}>{fDateShorter(item.expires_at)}</Typography>
                                            )}
                                        </TableCell>

                                        <TableCell sx={{ maxWidth: '1px' }}>
                                            {!(!!item.certificate_url) && (
                                                <Iconify icon={'akar-icons:minus'} width={25} height={25} /> 
                                            )}
                                            {(!!item.certificate_url) && (
                                                <IconButton 
                                                    size="large" 
                                                    color="primary"
                                                    href={item.certificate_url}
                                                    target="_blank">
                                                    <Iconify icon={'clarity:certificate-line'} color="#56397b" width={22} height={22} />
                                                </IconButton>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
                </Stack>
            </FormProvider>

            <ConfirmationDialog
                id={"confirm-training-assigment-dialog"}
                title="Confirm Training Assignment"
                message={`This training will be assigned to ${confirmModalState.employee?.first_name} ${confirmModalState.employee?.last_name} with the deadline set for ${confirmModalState.dueDate}.`}
                open={confirmModalState.isOpen}
                onCloseConfirm={handleConfirmButton}
            />
        </>
    );
}
