import { Link as RouterLink } from 'react-router-dom';
// @mui
// import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

import { LogoSm } from '../assets';
// import { borderRadius } from '@mui/system';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  logoUrl?: string;
  disabledLink?: boolean;
}

export default function Logo({ logoUrl = '', disabledLink = false, sx }: Props) {
  // const theme = useTheme();
  // const PRIMARY_LIGHT = theme.palette.primary.light;
  // const PRIMARY_MAIN = theme.palette.primary.main;
  // const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      {logoUrl &&
          <img
          src={logoUrl}
          alt="Logo"
          loading="lazy"
          style={imgStyles}
        />
      }

      { !logoUrl &&
        <img
          src={LogoSm}
          alt="Logo"
          loading="lazy"
        />
      }
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}

const imgStyles = {
  width: '40px',
  height: '40px',
  objectFit: 'cover',
  borderRadius: '100%'
} as React.CSSProperties;