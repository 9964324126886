import ReactGA from 'react-ga4';

import { createContext, ReactNode, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const response = await axios.get('/app/auth/whoami');
        const { user } = response.data.data;
        const { transaction_id, company } = user;

        if (
          company.pixel_fired != null &&
          company.pixel_fired === 0 &&
          process.env.REACT_APP_ENV === 'prod'
        ) {

          try {
            ReactGA.event('conversion', {
              send_to: 'AW-11399435147/NLG1CIb5mZwZEIun1rsq',
              value: 60,
              currency: 'USD',
              transaction_id: transaction_id ? transaction_id : '',
            });

            await axios.patch('/app/admin/company/pixel-fired');
          } catch (err) {
            console.error(err);
          }
        }

        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } else {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  const login = async (email: string, password: string) => {
    const LOGIN_URL = process.env.REACT_APP_LOGIN_URL || 'login-url-not-set';

    const response = await axios.post(LOGIN_URL, {
      email,
      password,
    });
    const { access_token, user } = response.data;
    const accessToken = access_token;

    setSession(accessToken);
    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };

  const register = async (
    email: string,
    password: string,
    first_name: string,
    last_name: string,
    company_name: string,
    password_confirmation: string,
    tier_id: string,
    card_holder_name: string,
    card_num: string,
    exp_year: string,
    exp_month: string,
    cvv: string,
    captcha_token: string,
    did_agree: string,
    is_trial: boolean
  ) => {
    const response = await axios.post('/app/auth/register', {
      first_name,
      last_name,
      email,
      password,
      company_name,
      password_confirmation,
      tier_id,
      card_holder_name,
      card_num,
      exp_year,
      exp_month,
      cvv,
      captcha_token,
      did_agree,
      is_trial,
    });
    const { access_token, user } = response.data;
    const accessToken = access_token;

    // window.localStorage.setItem('accessToken', accessToken);
    setSession(accessToken);

    dispatch({
      type: Types.Register,
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);

    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;

    dispatch({ type: Types.Logout });
  };

  const accessToken = () => {
    let tmpToken = window.localStorage.getItem('accessToken');
    let token = tmpToken ? tmpToken : '';

    return token;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        accessToken,
        initialize,
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
