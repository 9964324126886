// @mui
import { Stack, Typography, Link } from '@mui/material';
// assets
import { DocIllustration } from '../../../../assets';

// ----------------------------------------------------------------------

export default function NavbarDocs() {
  return (
    <Stack
      spacing={3}
      sx={{ px: 5, pb: 5, mt: 10, width: 1, textAlign: 'center', display: 'block' }}>
      <DocIllustration sx={{ width: 1 }} />
      
      {/* <Button variant="text" color="primary">Logout</Button> */}

      <div>
        {/* <Typography gutterBottom variant="subtitle1">
        Powered by Bizhaven
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Need help?
          <br /> Please check our docs
        </Typography> */}

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Powered by Bizhaven
        </Typography>

        <Link href="mailto:support@bzhaven.com">
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Need help?
          </Typography>
        </Link>
      </div>

    </Stack>
  );
}
