import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import { format } from 'date-fns';
// @mui
import {
    Box, Button, Typography, Avatar,
    DialogActions, DialogContent, FormControl, TextField
} from '@mui/material';
// components
import Iconify from '../../../components/Iconify';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// @types
import { TrainingCourseType } from '../../../@types/user';
// util
import { parseBizhavenError } from '../../../utils/parseError';
import { COURSE_TYPES } from 'src/config';

// ----------------------------------------------------------------------

type Props = {
    user: any;
    course: TrainingCourseType;
    courseTypeKey: string;
    courseId: string;
    onCancel: Function;
    onUserCompleted: Function;
    onUserCertificateChange: Function
};

export default function UserLearningProgress({ user, course, courseTypeKey, courseId, onCancel, onUserCompleted, onUserCertificateChange }: Props) {
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const [markCompleteDate, setMarkCompleteDate] = useState('');

    useEffect(() => {
        const curDate = format(new Date(), 'yyyy-MM-dd');
        setMarkCompleteDate(curDate);
    }, []);

    const onCertificateFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        uploadCertificateFile(e);
    }

    const uploadCertificateFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            let formData = new FormData();

            formData.append('user_id', user.id);

            if (e.target && e.target.files && e.target.files.length > 0) {
                formData.append('certificate', e.target.files[0]);
            }

            let url = `/app/shared/training/${courseId}/certificate`;

            const response = await axios.post(url, formData);

            if (isMountedRef.current) {
                let msg = 'Certificate saved!';
                enqueueSnackbar(msg);

                let { data } = response.data;

                // Not sure why but have to update both of these to 
                //  get the modal and parent page to update.
                user.certificate = data.certificate;
                user.certificate_url = data.certificate_url;
                onUserCertificateChange(user.id, courseId, data.certificate, data.certificate_url);
            }
        } catch (error) {
            if (isMountedRef.current) {
                let message = parseBizhavenError(error);
                error.message = message;

                enqueueSnackbar(error, {
                    variant: 'error'
                });
            }
        }
    }

    const submitComplete = async () => {
        try {
            let formData = new FormData();

            formData.append('user_id', user.id);
            formData.append('completed_at', markCompleteDate);

            let url = `/app/admin/training/${courseId}/mark-user-complete`;

            const response = await axios.post(url, formData);

            if (isMountedRef.current) {
                let msg = 'Course marked complete!';
                enqueueSnackbar(msg);

                let { data } = response.data;

                // Not sure why but have to update both of these to 
                //  get the modal and parent page to update.
                user.completed_modules_count = user.total_modules_count;
                user.completed_at = data.completed_at;
                user.expires_at = data.expires_at;
                onUserCompleted(data);
            }
        } catch (error) {
            if (isMountedRef.current) {
                let message = parseBizhavenError(error);

                enqueueSnackbar(message, {
                    variant: 'error'
                });
            }
        }
    };

    const hasCompletedCourse = () => !!user.completed_at;

    const getStatusColor = () => {
        let statusColor = (!hasCompletedCourse()) ? '#FF4842' : '#54D62C';
        return statusColor;
    }

    const onMarkCompleteDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = e.target;
        setMarkCompleteDate(value);
    }

    return (
        <>
            <DialogContent>
                <Typography
                    variant="h5"
                    sx={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textAlign: 'center',
                        textOverflow: 'ellipsis',
                        mb: 2
                    }}
                >
                    {course.title}
                </Typography>

                <Avatar
                    alt={user?.first_name}
                    src={user?.profile_image_url}
                    sx={{
                        m: 'auto',
                        borderWidth: '2px',
                        borderStyle: 'solid',
                        borderColor: getStatusColor()
                    }}
                />

                <Typography
                    color="text.secondary"
                    sx={{
                        mt: 1,
                        textAlign: 'center',
                        fontSize: '16px'
                    }}
                >
                    {user.first_name} {user.last_name}
                </Typography>

                { (!user.due_at) && (
                    <Box textAlign="center">
                        <Typography
                            color="text.secondary"
                            sx={{
                                mt: 1,
                                textAlign: 'center',
                                fontStyle: 'italic'
                            }}
                        >
                            Course has not been assigned.
                        </Typography>
                        <Typography
                            color="text.secondary"
                            sx={{
                                mt: 1,
                                textAlign: 'center',
                                fontStyle: 'italic'
                            }}
                        >
                            Please add due date to assign course.
                        </Typography>
                    </Box>
                )}

                {!!user.due_at && !hasCompletedCourse() && (
                    <Box textAlign="center">
                        <Typography
                            color="text.secondary"
                            sx={{
                                mt: 1,
                                textAlign: 'center',
                                fontStyle: 'italic'
                            }}
                        >
                            Course has not been completed. <br />
                        </Typography>

                        {courseTypeKey === COURSE_TYPES.IN_PERSON && (
                            <Button
                                variant="contained"
                                color="success"
                                sx={
                                    { mt: 2 }
                                }
                                onClick={submitComplete}
                            >
                                Mark Complete
                            </Button>
                        )}
                    </Box>
                )}

                {(courseTypeKey !== COURSE_TYPES.IN_PERSON) && (
                    <Typography
                        color="text.secondary"
                        sx={{
                            mt: 1,
                            textAlign: 'center',
                            fontStyle: 'italic'
                        }}
                    >
                        Progress: {user.completed_modules_count} / {user.total_modules_count}
                    </Typography>
                )}

                {hasCompletedCourse() && (
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            color="text.secondary"
                            sx={{
                                mt: 1,
                                mb: 2,
                                fontStyle: 'italic',
                                fontSize: '16px'
                            }}
                        >
                            Course has been completed.
                        </Typography>

                        <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            sx={{
                                mt: 1,
                                mb: 2,
                            }}
                        >
                            Certificate of Completion (PDF)
                        </Typography>
                        <div>
                            <input
                                accept=".pdf"
                                id="raised-button-file"
                                multiple
                                type="file"
                                onChange={onCertificateFileChange}
                                style={
                                    { display: 'none' }
                                }
                            />
                            <label htmlFor="raised-button-file">
                                <Button
                                    variant="contained"
                                    component="span"
                                    sx={{
                                        m: 1,
                                        width: '100px'
                                    }}
                                    startIcon={<Iconify icon={'ant-design:upload-outlined'} />}
                                >
                                    Add
                                </Button>
                            </label>

                            {!!user.certificate_url && (
                                <Button
                                    variant="contained"
                                    sx={{
                                        m: 1,
                                        width: '100px'
                                    }}
                                    startIcon={<Iconify icon={'eva:eye-outline'} />}
                                    href={user.certificate_url}
                                    target="_blank"
                                >
                                    View
                                </Button>
                            )}
                        </div>
                    </Box>
                )}
            </DialogContent>

            <DialogActions sx={{ paddingTop: '0px !important' }}>
                <Button variant="outlined" color="inherit" onClick={() => onCancel()}>
                    Close
                </Button>
            </DialogActions>
        </>
    );
}
