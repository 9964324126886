import { useState, useEffect } from 'react';
// @mui
import {
    Stack, Dialog, Button, Grid,
    DialogProps, DialogTitle, DialogContent, DialogActions, TextField,
    FormControl, InputLabel, Select, MenuItem
} from '@mui/material';

// ----------------------------------------------------------------------

type DocumentCategoryType = {
    id: string;
    text_id: string;
    name: string;
    type: string;
    unique_id: string;
}

interface Props extends DialogProps {
    name: string;
    type: string;
    typeId: string;
    categories: DocumentCategoryType[];
    forcedCategory: string;
    open: boolean;
    onCloseConfirm: (docInfo: any) => void;
}

// ----------------------------------------------------------------------

export default function ConfirmSaveDocDialog({ name, type, typeId, categories, forcedCategory, open, onCloseConfirm }: Props) {
    const [docName, setDocName] = useState('');
    const [curCategory, setCurCategory] = useState('');

    const handleTextChange = (event: any) => {
        setDocName(event.target.value);
    };

    const handleCategorySelect = (event: any) => {
        const { target: { value } } = event;
        setCurCategory(value);
    };

    const getSelectedCategory = (uniqueId: string): DocumentCategoryType | null => {
        let cat = categories.find((item) => item.unique_id.toString() === uniqueId.toString());

        if(!cat){
            return null;
        }else{
            return cat;
        }
    }

    const handleSave = () => {
        const selectedCategory = getSelectedCategory(curCategory);

        const docInfo = {
            docName: docName,
            category: selectedCategory,
        };

        onCloseConfirm(docInfo);
    }

    useEffect(() => {
        setDocName(name);

        if(forcedCategory){
            const cat = categories.find((item) => item.text_id === forcedCategory);
            if(cat){
                setCurCategory(cat.unique_id);
            }
        }else{
            categories.forEach((item) => {
                if(item.type === type && item.id === typeId){
                    setCurCategory(item.unique_id);
                }
            });
        }

    }, [open, name, type, typeId, categories, forcedCategory]);

    return (
        <Dialog fullWidth maxWidth="sm" open={open} onClose={() => onCloseConfirm('')}>
            <DialogTitle sx={{ p: (theme) => theme.spacing(3, 3, 2, 3) }}>Save Document</DialogTitle>

            <DialogContent dividers sx={{ pt: 1, pb: 0, border: 'none' }}>
                <Stack spacing={2} direction='column'>
                    <TextField
                        fullWidth
                        id={'doc-name'}
                        label="Document Name"
                        value={docName}
                        onChange={handleTextChange}
                    />

                    <FormControl fullWidth>
                        <InputLabel>Category</InputLabel>
                        <Select
                            value={curCategory}
                            label="Category"
                            onChange={handleCategorySelect}
                            disabled={!!forcedCategory}
                        >
                            {categories.map((option: any) => (
                                <MenuItem key={option.unique_id} value={option.unique_id}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Grid container spacing={3}>
                    <Grid item xs={6}>
                        <Button variant="outlined" color="inherit" onClick={() => onCloseConfirm('')}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                        <Button
                            id={"save-doc"}
                            variant="contained" 
                            onClick={handleSave}
                            disabled={!docName || !curCategory}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
}
