export const ADMIN_MENU_OPTIONS = [
    {
        icon: 'bx:bx-user',
        label: 'Profile',
        linkTo: '/admin/profile',
    },
    {
        icon: 'bx:building',
        label: 'Company',
        linkTo: '/admin/profile?tab=company',
    },
    {
        icon: 'carbon:document',
        label: 'Manuals',
        linkTo: '/admin/profile?tab=manuals',
    },
    {
        icon: 'bi:gear',
        label: 'Settings',
        linkTo: '/admin/profile?tab=settings',
    },
    {
        icon: 'akar-icons:lock-on',
        label: 'Security',
        linkTo: '/admin/profile?tab=security',
    },
    // {
    //     icon: 'akar-icons:grid',
    //     label: 'Integrations',
    //     linkTo: '/admin/profile?tab=integrations',
    // },
];
