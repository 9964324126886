import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
// routes
import { PATH_AUTH } from '../../../../routes/paths';
// hooks
import useAuth from '../../../../hooks/useAuth';
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
// components
import MyAvatar from '../../../../components/MyAvatar';
import MenuPopover from '../../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../../components/animate';
import Iconify from '../../../../components/Iconify';
import { ADMIN_MENU_OPTIONS } from "../../../../pages/constants/admin-menu-options";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  ...ADMIN_MENU_OPTIONS,
  // Additional properties or modifications as needed
];

const SUBSCRIPTION_MENU_OPTION = {
  icon: 'wpf:renew-subscription',
  label: 'Subscription',
  linkTo: '/admin/profile?tab=subscription',
};

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const { user, logout } = useAuth();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (url: any = null) => {
    setOpen(null);
    if (url) {
        navigate(url, { replace: true });
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        id={"account-dropdown"}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
      <MyAvatar />

      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            { user?.first_name } { user?.last_name }
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            { user?.position }
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        
          <Stack sx={{ p: 1 }}>
            {MENU_OPTIONS.map((option) => (
              <MenuItem 
                key={option.label}
                onClick={() => handleClose(option.linkTo)}
                component={RouterLink}
                to={option.linkTo}
              >
                <Iconify icon={option.icon} width={20} height={20} mr={1} />
                {option.label}
              </MenuItem>
            ))}
            
            {!!(user && user.is_billing_admin) && (
              <MenuItem 
                key={SUBSCRIPTION_MENU_OPTION.label} 
                to={SUBSCRIPTION_MENU_OPTION.linkTo}
                component={RouterLink}
                onClick={handleClose}>
                <Iconify icon={SUBSCRIPTION_MENU_OPTION.icon} width={20} height={20} mr={1} />
                {SUBSCRIPTION_MENU_OPTION.label}
              </MenuItem>
            )}
          </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout}  sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
