import { useState, useCallback, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'src/utils/axios';
import moment from 'moment';
// @mui
import {
    Grid, Typography, Box,
    Button, Link, DialogTitle,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
// utils
import { fDateShort } from '../../../../utils/formatTime';
// hooks
import useIsMountedRef from '../../../../hooks/useIsMountedRef';
// components
import { DialogAnimate } from '../../../../components/animate';
import { SkeletonGraph } from '../../../../components/skeleton';

// ----------------------------------------------------------------------

type AssignedUserType = {
    id: string;
    training_access_user_id: string;
    training_title: string;
    first_name: string;
    last_name: string;
    due_at: string;
    completed_at: string;
}

const initUsers: AssignedUserType[] = [];

type ModalState = {
    isOpenModal: boolean;
    assignments: any;
};
const initialModalState: ModalState = {
    isOpenModal: false,
    assignments: initUsers,
};

// ----------------------------------------------------------------------

export default function UpcomingCoursesSection() {
    const isMountedRef = useIsMountedRef();
    const [modalState, setModalState] = useState(initialModalState);
    const [isLoading, setIsLoading] = useState(false);
    const [users30Day, setUsers30Day] = useState(initUsers);
    const [users90Day, setUsers90Day] = useState(initUsers);

    const getUpcomingCourseData = useCallback(async () => {
        try {
            let url = `/app/admin/training/assignments`;
            let data = {
                due_before: moment().add(90, 'day').format('YYYY-MM-DD')
            }

            const response = await axios.get(url, {
                params: data,
            });

            if (isMountedRef.current) {
                let { data } = response.data;

                await setAssignedData(data.users);
            }
        } catch (err) {
            console.log(err);
        }
    }, [isMountedRef]);

    const setAssignedData = async (data: any) => new Promise((resolve, reject) => {
        let tempUsers30Day: AssignedUserType[] = [];
        let tempUsers90Day: AssignedUserType[] = [];

        const compareDate30 = moment().add(30, 'day').format('YYYY-MM-DD');

        for (let i = 0; i < data.length; i++) {
            let curUser: AssignedUserType = data[i];

            if(!!curUser.completed_at){
                continue;
            }else if (curUser.due_at > compareDate30) {
                tempUsers90Day.push(curUser);
            } else {
                tempUsers30Day.push(curUser);
            }
        }

        setUsers30Day(tempUsers30Day);
        setUsers90Day(tempUsers90Day);
        resolve(null);
    });

    const init = useCallback(async () => {
        setIsLoading(true);

        await getUpcomingCourseData();

        setIsLoading(false);
    }, [getUpcomingCourseData]);

    useEffect(() => {
        init();
    }, [init]);

    /*********
     * MODAL *
     *********/

    const openModal = (type: string) => {
        if (type === '30_day') {
            setModalState({
                isOpenModal: true,
                assignments: users30Day,
            });
        } else if (type === '90_day') {
            setModalState({
                isOpenModal: true,
                assignments: users90Day,
            });
        }
    }

    const closeModal = () => {
        setModalState({
            isOpenModal: false,
            assignments: initUsers,
        });
    }

    return (
        <>
            {isLoading ? (
                <SkeletonGraph />
            ) : (
                <>
                    <Grid container spacing={3} sx={{ p: 3, pt: 0 }}>
                        <Grid item xs={6}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography variant="body2" sx={{ ...SUBHEADER_STYLE }}>Due In Next 30 Days</Typography>
                                <Box>
                                    <Button
                                        onClick={() => (openModal('30_day'))}
                                        variant="text"
                                        size="large"
                                        sx={{ ...BUTTON_STYLE }}
                                    >
                                        {users30Day.length}
                                    </Button>
                                </Box>
                                <Box>
                                    <Link
                                        to={'/admin/tracker?due_at=next_30_days'}
                                        component={RouterLink}
                                    >
                                        View All
                                    </Link>
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={6}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography variant="body2" sx={{ ...SUBHEADER_STYLE }}>Due in Next 90 Days</Typography>
                                <Box>
                                    <Button
                                        onClick={() => (openModal('90_day'))}
                                        variant="text"
                                        size="large"
                                        sx={{ ...BUTTON_STYLE }}
                                    >
                                        {users90Day.length}
                                    </Button>
                                </Box>
                                <Box>
                                    <Link
                                        to={'/admin/tracker?due_at=next_90_days'}
                                        component={RouterLink}
                                    >
                                        View All
                                    </Link>
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>
                </>
            )}

            <DialogAnimate open={modalState.isOpenModal} onClose={closeModal}>
                <DialogTitle sx={{ mb: 1 }}>Upcoming Courses</DialogTitle>

                <TableContainer component={Paper} sx={{ p: 2 }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Training</TableCell>
                                <TableCell>Employee</TableCell>
                                <TableCell>Due At</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {modalState.assignments.map((row: AssignedUserType) => (
                                <TableRow
                                    key={row.training_access_user_id}
                                >
                                    <TableCell>{row.training_title}</TableCell>
                                    <TableCell>{row.first_name} {row.last_name}</TableCell>
                                    <TableCell>{fDateShort(row.due_at)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogAnimate>
        </>
    );
}

const SUBHEADER_STYLE = {
    margin: '0',
    lineHeight: '1.5714285714285714',
    fontSize: '0.875rem',
    fontWeight: '400',
    color: '#637381',
    display: 'block',
    marginTop: '4px'
};

const BUTTON_STYLE = {
    width: '90px',
    fontSize: '2rem',
    marginTop: '4px',
    marginBottom: '4px'
};
