// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_SUPER_DASHBOARD = '/super/dashboard';
export const PATH_ADMIN_DASHBOARD = '/admin/dashboard';
export const PATH_EMPLOYEE_DASHBOARD = '/employee/dashboard';

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    one: path(ROOTS_DASHBOARD, '/one'),
    two: path(ROOTS_DASHBOARD, '/two'),
    three: path(ROOTS_DASHBOARD, '/tree')
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user/four'),
    five: path(ROOTS_DASHBOARD, '/user/five'),
    six: path(ROOTS_DASHBOARD, '/user/six'),
  },
};