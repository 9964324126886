import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { 
  Box, Stack, Button, Alert, DialogActions,Tooltip,IconButton
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// util
import { parseBizhavenError } from '../../../utils/parseError';

// ----------------------------------------------------------------------

const getInitialValues = (toDo: any) => {
  // default to current date
  let curr = new Date();
  let date = curr.toISOString().substr(0,10);

  let _toDo = {
    title: '',
    description: '',
    deadline: date,
    afterSubmit: ''
  };

  if(toDo != null && toDo.title){
    let date = toDo.deadline.substr(0,10);

    _toDo = {
      title: toDo.title,
      description: toDo.description,
      deadline: date,
      afterSubmit: ''
    };
  }

  return _toDo;
};

// ----------------------------------------------------------------------

type FormValuesProps = {
  title: string;
  description: string;
  deadline: string;
};

type Props = {
  onCancel: VoidFunction;
  toDo: FormValuesProps|null;
  toDoId: string|null;
};

export default function ToDoForm({ onCancel, toDo, toDoId }: Props) {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const EventSchema = Yup.object().shape({
    title: Yup.string().max(5000).required('Title is required'),
    description: Yup.string().max(5000).required('Description is required'),
    deadline: Yup.string().max(5000).required('Deadline is required'),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(toDo)
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      var formData = new FormData();

      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("deadline", data.deadline);

      let url = '/app/admin/to-do';

      // switch to update endpoint
      if(toDoId){
        url += '/' + toDoId
        formData.append('_method', 'PUT');
      }

      await axios.post(url, formData);

      if (isMountedRef.current) {
        let msg = 'To do updated!';

        enqueueSnackbar(msg);
        onCancel();
        reset();
      }
    } catch (error) {
      console.log(error);

      if (isMountedRef.current) {
        let message = parseBizhavenError(error);
        error.message = message;
        
        setError('afterSubmit', error);
      }
    }
  };

  const onDelete = async() => {
    try {
      let url = `/app/admin/to-do/${toDoId}`;
      let data = {
        '_method': 'DELETE'
      };

      await axios.post(url, data);

      if (isMountedRef.current) {
        let msg = 'To do deleted!';

        enqueueSnackbar(msg);
        onCancel();
        reset();
      }
    } catch (error) {
      console.log(error);

      if (isMountedRef.current) {
        let message = parseBizhavenError(error);
        error.message = message;
        
        setError('afterSubmit', error);
      }
    }
  }

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <RHFTextField name="title" label="Title" multiline />

        <RHFTextField name="description" label="Description" multiline rows={4} />

        <RHFTextField type="date" name="deadline" label="Deadline" InputLabelProps={{ shrink: true }} />

        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      </Stack>

      <DialogActions>
      {toDoId && (
          <Tooltip title="Delete To Do">
            <IconButton onClick={onDelete} color="error">
              <Iconify icon="eva:trash-2-outline" width={20} height={20} />
            </IconButton>
          </Tooltip>
        )}
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting}
          loadingIndicator="Loading..."
        >
          Update
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
