import { useState, useCallback, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
// form
import { useForm, Controller } from 'react-hook-form';
// @mui
import {
  Card, Stack, Checkbox, MenuItem, IconButton, CheckboxProps, 
  FormControlLabel, Typography, Button, Container, DialogTitle, Box
} from '@mui/material';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import { DialogAnimate } from '../../../components/animate';
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';
import { ToDoForm } from '.';
// util
import { parseBizhavenError } from '../../../utils/parseError';
import { fDateVeryShort } from '../../../utils/formatTime';
import useMediaQuery from "@mui/material/useMediaQuery";

// ----------------------------------------------------------------------

type ToDo = {
  assigned_to: string;
  company_id: string;
  created_at: string;
  created_by: string;
  deadline: string;
  description: string;
  id: string;
  status: number;
  title: string;
  updated_at: string;
};
const initialToDos: Array<ToDo> = [];

type ModalState = {
  isOpenModal: boolean;
  selectedToDo: any;
  selectedToDoId: string|null;
};
const initialModalState: ModalState = {
  isOpenModal: false,
  selectedToDo: null,
  selectedToDoId: null
};

// ----------------------------------------------------------------------

type FormValuesProps = {
  taskCompleted: string[];
};

export default function AdminTasks() {
  const isMountedRef = useIsMountedRef();
  const [toDos, setToDos] = useState(initialToDos);
  const [modalState, setModalState] = useState(initialModalState);
  const { enqueueSnackbar } = useSnackbar();

  const getToDos = useCallback(async () => {
      try {
        const response = await axios.get('/app/admin/to-do', {
            params: {  },
        });

        if (isMountedRef.current) {
          let { data } = response.data;

          let formattedData: Array<ToDo> = data;

          setToDos(formattedData);
        }
      } catch (err) {
        console.log(err);
      }
  }, [isMountedRef]);

  useEffect(() => {
    getToDos();
  }, [getToDos]);

  const onSubmitToggleStatus = async (toDoId: string) => {
    try {

      let url = `/app/admin/to-do/${toDoId}/toggle-status`;

      await axios.post(url);

      if (isMountedRef.current) {
        let msg = 'To do updated!';

        enqueueSnackbar(msg);
      }
    } catch (error) {
      console.log(error);

      if (isMountedRef.current) {
        let message = parseBizhavenError(error);
        enqueueSnackbar('Error: ' + message, { 
          variant: 'error',
        });
      }
    }
  };

  const onCheckboxChange = (toDo: ToDo) => {
    let newToDos = toDos.map((item) => {
      if(item.id === toDo.id){
        item.status = (item.status.toString() === '1') ? 2 : 1;
      }
      
      return item;
    })

    setToDos(newToDos);
    onSubmitToggleStatus(toDo.id);
  }

  const handleCloseModal = () => {
    getToDos();
    closeModal();
  };

  // OPEN MODAL
  const openModal = (toDo: ToDo|null) => {
    let _selectedTodo = toDo;
    let toDoId = (toDo) ? toDo.id : null;
    
    setModalState({
      isOpenModal: true,
      selectedToDo: _selectedTodo,
      selectedToDoId: toDoId
    });
  }

  // CLOSE MODAL
  const closeModal = () => {
    setModalState({
      ...modalState,
      isOpenModal: false,
      selectedToDo: null,
      selectedToDoId: null
    });
  }

  const { control } = useForm<FormValuesProps>({
    defaultValues: {
      taskCompleted: ['Stakeholder Meeting'],
    },
  });

  return (
    <>
      <Card>
        {/* <CardHeader title="To Do" /> */}
        <Container sx={{ padding: 1, paddingTop: 2 }}>
          <Typography variant="h5" sx={{ display: "inline-block", marginRight: 5 }}>To Do</Typography>
        </Container>

        <Controller
          name="taskCompleted"
          control={control}
          render={({ field }) => (
              <>
                {toDos.map((toDo) => (
                  <TaskItem
                    key={toDo.id}
                    toDo={toDo}
                    checked={toDo.status === 2}
                    onChange={() => { onCheckboxChange(toDo) }}
                    onEdit={() => {openModal(toDo)}}
                  />
                ))}
              </>
            )
          }
        />
          <Box sx={{ p: 3, textAlign: 'center' }}>
            <Button 
              size="small" 
              variant="contained" 
              color="primary" 
              onClick={() => openModal(null)} 
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              Add a To Do
            </Button>
          </Box>
      </Card>

      <DialogAnimate open={modalState.isOpenModal} onClose={handleCloseModal}>
        <DialogTitle sx={{ mb: 1 }}>To Do</DialogTitle>

        <ToDoForm 
            onCancel={handleCloseModal} 
            toDo={modalState.selectedToDo}
            toDoId={modalState.selectedToDoId}
        />
      </DialogAnimate>
    </>
  );
}

// ----------------------------------------------------------------------

interface TaskItemProps extends CheckboxProps {
  toDo: ToDo;
  onEdit: VoidFunction;
}

function TaskItem({ toDo, onEdit, checked, onChange }: TaskItemProps) {
  const maxWidth = useMediaQuery('(min-width:1536px)') ? '250px' : '900px';

  const LABEL_STYLE = {
    width: "auto",
    maxWidth,
    display: "block"
  }


  return (
    <Stack
      direction="row"
      sx={{
        px: 2,
        py: 0.75,
        ...(checked && {
          color: 'text.disabled',
          textDecoration: 'line-through',
        }),
      }}
    >
      <FormControlLabel
        sx={{ flexGrow: 1, m: 0, overflowWrap: "break-word" }}
        control={<Checkbox checked={checked} onChange={onChange} />}
        label={
          <Typography sx={{ ...LABEL_STYLE }}>
            { toDo.deadline && (
              <Box component="span"  sx={{ display: "inline", fontWeight: "bold" }}>
                {fDateVeryShort(toDo.deadline.substring(0, 10))}
              </Box>
            )} - {toDo.title}
          </Typography>
        }
      />
      
      <MoreMenuButton onEdit={onEdit} />
    </Stack>
  );
}

// ----------------------------------------------------------------------
type MoreMenuPros = {
  onEdit: VoidFunction;
};

function MoreMenuButton({ onEdit }: MoreMenuPros) {
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleOnEdit = () => {
    handleClose();
    onEdit();
  }

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  return (
    <>
      <IconButton size="large" onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 'auto',
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem onClick={handleOnEdit}>
          <Iconify icon={'eva:edit-fill'} sx={{ ...ICON }}/>
          Edit
        </MenuItem>
      </MenuPopover>
    </>
  );
}
