import { useState, useCallback, useEffect } from 'react';
import axios from 'src/utils/axios';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader } from '@mui/material';
// utils
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import { BaseOptionChart } from '../../../components/chart';
import { SkeletonGraph } from '../../../components/skeleton';

// ----------------------------------------------------------------------

// const CHART_HEIGHT = 392;
// const LEGEND_HEIGHT = 72;

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//   height: CHART_HEIGHT,
//   marginTop: theme.spacing(5),
//   '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
//   '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
//     overflow: 'visible',
//   },
//   '& .apexcharts-legend': {
//     height: LEGEND_HEIGHT,
//     alignContent: 'center',
//     position: 'relative !important' as 'relative',
//     borderTop: `solid 1px ${theme.palette.divider}`,
//     top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
//   },
// }));

// ----------------------------------------------------------------------

export default function AdminEngagementGraph() {
  const isMountedRef = useIsMountedRef();
  const [chartData, setChartData] = useState([{ name: '', type: '', data: [] }]);
  const [chartLabels, setChartLabels] = useState([]);

  const getEngagementStats = useCallback(async () => {
    try {
      const response = await axios.get('/app/admin/dashboard/engagement', {
        params: {  },
      });

      if (isMountedRef.current) {
        let { data } = response.data;
        
        data.values = data.values.map((item: any) => parseFloat(item))

        let chartData = [{
          name: 'Engagement',
          type: 'area',
          data: data.values,
        }];

        setChartLabels(data.labels);
        setChartData(chartData);
      }
    } catch (err) {
      console.log(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getEngagementStats();
  }, [getEngagementStats]);

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [2] },
    plotOptions: { bar: { columnWidth: '14%' } },
    fill: { type: ['solid'] },
    labels: chartLabels,
    xaxis: { type: 'datetime' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y: number) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} actions`;
          }
          return y;
        },
      },
    },
  });

  return (
    <Card>
      <CardHeader title="30 Day Employee Engagement"  subheader="Past Month" />

      { chartLabels.length ? (
          <ReactApexChart type="line" series={chartData} options={chartOptions} height={280} />
        ) : (
          <SkeletonGraph />
        )
      }
    </Card>
  );
}
