import { useState, useCallback, useEffect } from 'react';
import axios from 'src/utils/axios';
// @mui
import { Grid, Card, CardHeader, Typography } from '@mui/material';
// utils
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import { SkeletonGraph } from '../../../components/skeleton';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

// const CHART_HEIGHT = 392;
// const LEGEND_HEIGHT = 72;

// const ChartWrapperStyle = styled('div')(({ theme }) => ({
//   height: CHART_HEIGHT,
//   marginTop: theme.spacing(5),
//   '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
//   '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
//     overflow: 'visible',
//   },
//   '& .apexcharts-legend': {
//     height: LEGEND_HEIGHT,
//     alignContent: 'center',
//     position: 'relative !important' as 'relative',
//     borderTop: `solid 1px ${theme.palette.divider}`,
//     top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
//   },
// }));

// ----------------------------------------------------------------------

export default function AdminAppUsage() {
  const isMountedRef = useIsMountedRef();

  const [usageData, setUsageData] = useState({
    check_in_count: 0,
    completed_goal_deliverables_count: 0,
    completed_survey_count: 0,
    completed_trainings_count: 0,
    loaded: false,
  });

  const getUsageData = useCallback(async () => {
    try {
      const response = await axios.get('/app/admin/dashboard/usage', {
        params: {},
      });

      if (isMountedRef.current) {
        let { data } = response.data;

        data.loaded = true;

        setUsageData(data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getUsageData();
  }, [getUsageData]);

  return (
    <Card sx={{ mt: 3 }}>
      <CardHeader title="30 Day Usage" subheader="Past Month" />

      {!usageData.loaded ? (
        <SkeletonGraph />
      ) : (
        <Grid container spacing={3} sx={{ p: 3 }}>
          <Grid item xs={4}>
            <Typography color="primary" sx={{ textAlign: 'center' }}>
              <Iconify icon="bxs:bar-chart-alt-2" width={100} height={100} />
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
              Check-ins: {usageData.check_in_count}
            </Typography>

            <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
              Goal Deliverables: {usageData.completed_goal_deliverables_count}
            </Typography>

            <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
              Completed Surveys: {usageData.completed_survey_count}
            </Typography>

            <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
              Completed Trainings: {usageData.completed_trainings_count}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Card>
  );
}
