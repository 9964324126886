// @mui
import { Stack, Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonGraph() {
  return (
    // <Stack
    //   sx={{ margin: 1 }}
    // >
    //   <Skeleton
    //       key={1}
    //       variant="text"
    //       sx={{ width: 1, height: 200, borderRadius: 2 }}
    //   />
    // </Stack>

    <Stack
      sx={{ ml: 3, mr: 3 }}
    >
      <Skeleton
        key={1}
        variant="text"
        sx={{ width: 1, height: 200, borderRadius: 2, transform: 'scale(1, 0.8)' }}
      />
    </Stack>
  );
}
