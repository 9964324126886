import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Alert, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// util
import { parseBizhavenError } from '../../../utils/parseError';

// ----------------------------------------------------------------------

type FormValuesProps = {
    email: string;
    password: string;
    code: string;
    afterSubmit?: string;
};

type Props = {
    email: string;
    password: string;
    onCancel: Function;
}

// ----------------------------------------------------------------------

export default function AuthValidate2FACodeForm({ email, password, onCancel }: Props) {
    const { login } = useAuth();
    const isMountedRef = useIsMountedRef();
    const { enqueueSnackbar } = useSnackbar();
    const [resendingCode, setResendingCode] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
        code: Yup.string().required('2FA code is required'),
    });

    const defaultValues = {
        email,
        password,
        code: ''
    };

    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        setError,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;

    const onSubmit = async (data: FormValuesProps) => {
        try {
            // short wait to avoid flicker from fast loading
            await new Promise((resolve) => setTimeout(resolve, 500));

            const url = `/app/auth/2fa/check-code`;

            await axios.post(url, data);

            submitLogin(data);
        } catch (error) {
            const _message = parseBizhavenError(error);

            setError('afterSubmit', {
                ...error,
                message: _message,
            });
        }
    };

    const resendCode = async () => {
        try {
            setResendingCode(true);

            // short wait to avoid flicker from fast loading
            await new Promise((resolve) => setTimeout(resolve, 500));

            const url = '/app/auth/2fa/send-code';
            const data = { email, password };
            const response = await axios.post(url, data);

            const { msg } = response.data.data;
            enqueueSnackbar(msg, { variant: 'success', autoHideDuration: 10000 });

            setResendingCode(false);
        } catch (error) {
            setResendingCode(false);

            const _message = parseBizhavenError(error);

            setError('afterSubmit', {
                ...error,
                message: _message,
            });
        }
    };

    const submitLogin = async (data: FormValuesProps) => {
        try {
            await login(data.email, data.password);
        } catch (error) {
            if (isMountedRef.current) {
                let message = parseBizhavenError(error);
                let errorOption = { message: message };

                setError('afterSubmit', errorOption);
            }
        }
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography
                    variant="caption"
                    component="div"
                    sx={{ ...MY_STYLE.msg }}
                >
                    Please enter the verification code below
                </Typography>
            </Stack>

            <Stack spacing={3} sx={{ my: 2 }}>

                {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

                <RHFTextField name="code" label="Code" />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                <div onClick={(e) => {
                    onCancel()
                }}>
                    <Typography variant="subtitle2" color="primary" sx={{ ...MY_STYLE.link }}>
                        <Iconify sx={{ verticalAlign: 'middle' }} icon={'material-symbols:chevron-left'} /> Back to First Step
                    </Typography>
                </div>
            </Stack>

            <Stack direction="column" alignItems="center" spacing={3} justifyContent="space-between" sx={{ my: 2 }}>
                <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                >
                    Login
                </LoadingButton>

                <LoadingButton
                    color="secondary"
                    fullWidth
                    size="large"
                    variant="contained"
                    loading={resendingCode}
                    onClick={resendCode}
                >
                    Resend Code
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}

const MY_STYLE = {
    msg: {
        textAlign: 'left' as 'left',
        fontStyle: 'italic',
        color: 'text.disabled',
        fontSize: '1rem',
    },
    link: {
        cursor: "pointer",
        "&:hover": {
            textDecoration: "underline",
        },
    },
}