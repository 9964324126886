import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import { useState, useCallback, useEffect } from 'react';
// form
import { useForm, } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Stack, Button, Alert, DialogActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFTextField, RHFSelect  } from '../../../components/hook-form';
// hooks
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// util
import { parseBizhavenError } from '../../../utils/parseError';

// ----------------------------------------------------------------------

const getInitialValues = () => {
  const _announcement = {
    title: '',
    description: '',
    type: '',
    department_id: '',
    afterSubmit: ''
  };

  return _announcement;
};

// ----------------------------------------------------------------------

type FormValuesProps = {
  title: string;
  description: string;
  type: string;
  department_id: string;
  afterSubmit?: string;
};

type Props = {
  onCancel: VoidFunction;
};

export default function AnnouncementForm({ onCancel }: Props) {
  const isMountedRef = useIsMountedRef();
  const [formOptions, setFormOptions] = useState({ importance_options: [], departments: [] });
  const { enqueueSnackbar } = useSnackbar();

  const EventSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Title is required'),
    description: Yup.string().max(5000).required('Description is required'),
    type: Yup.string().max(5000).required('Importance is required'),
    department_id: Yup.string().max(5000).required('Department is required'),
  });

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues: getInitialValues(),
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const getFormOptions = useCallback(async () => {
    try {
      const response = await axios.get('/app/admin/announcement/form-options', {
        params: {  },
      });

      if (isMountedRef.current) {
        let { data } = response.data;

        setFormOptions(data);
      }
    } catch (err) {
      console.log(err);
    }
  }, [isMountedRef]);

  useEffect(() => {
    getFormOptions();
  }, [getFormOptions]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const newAnnouncement = {
        title: data.title,
        description: data.description,
        type: data.type,
        department_id: data.department_id
      };

      await axios.post('/app/admin/announcement', {
        ...newAnnouncement
      });

      if (isMountedRef.current) {
        enqueueSnackbar('Announcement created!');
        onCancel();
        reset();
      }
    } catch (error) {
      if (isMountedRef.current) {
        let message = parseBizhavenError(error);
        error.message = message;
        
        setError('afterSubmit', error);
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} sx={{ p: 3 }}>
        <RHFTextField name="title" label="Title" />

        <RHFTextField name="description" label="Description" multiline rows={4} />

        <RHFSelect name="type" label="Importance" placeholder="Importance">
            <option value="" />
            {formOptions.importance_options.map((option: any) => (
              <option key={option.key} value={option.key}>
                {option.label}
              </option>
            ))}
          </RHFSelect>

          <RHFSelect name="department_id" label="Department" placeholder="Importance">
            <option value="" />
            {formOptions.departments.map((option: any) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
          </RHFSelect>

        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
      </Stack>

      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onCancel}>
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          loading={isSubmitting}
          loadingIndicator="Loading..."
        >
          Add
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
